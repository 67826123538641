import { createDakpan } from 'dakpan';
import { sha256 } from 'js-sha256';

import { app, PwaLocalStorageKey } from '../constants';


type State = {
  onboarding: boolean,
  pin?: string,
  active?: number,
  token: {
    id: number,
    name: string,
    token: string
  }[],
  unlocked: boolean
};

export const [AuthProvider, useAuth] = createDakpan<State>(() => {

	let oldPin = localStorage.getItem('pin');
	if (oldPin !== null) {
		localStorage.removeItem('i18nextLng'); // tmp here
		localStorage.removeItem('pin');
		localStorage.setItem(PwaLocalStorageKey.AUTH_PIN, oldPin);
	}
	let oldActive = localStorage.getItem('active');
	if (oldActive !== null) {
		localStorage.removeItem('active');
		localStorage.setItem(PwaLocalStorageKey.AUTH_ACTIVE, oldActive);
	}
	let oldToken = localStorage.getItem('token');
	if (oldToken !== null) {
		localStorage.removeItem('token');
		localStorage.setItem(PwaLocalStorageKey.AUTH_TOKEN_LIST, oldToken);
	}
	let oldSubmitted = localStorage.getItem('submitted');
	if (oldSubmitted !== null) {
		localStorage.removeItem('submitted');
	}

  const pin = localStorage.getItem(PwaLocalStorageKey.AUTH_PIN) || undefined;
  return {
    onboarding: false,
    pin,
    active: (pin && Number(localStorage.getItem(PwaLocalStorageKey.AUTH_ACTIVE))) || undefined,
    token: (pin && JSON.parse(localStorage.getItem(PwaLocalStorageKey.AUTH_TOKEN_LIST) || '[]')) || [],
	unlocked: process.env.NODE_ENV !== 'production' /* auto unlock for developer */
  };
})({
  login: (pin: string) => (state) => {
    let hashedPin = sha256.hmac(app, pin);

    localStorage.setItem(PwaLocalStorageKey.AUTH_PIN, hashedPin);

    return {
      ...state,
      pin: hashedPin,
      unlocked: true,
      onboarding: false
    };
  },
  logout: () => (state) => {
    localStorage.removeItem(PwaLocalStorageKey.AUTH_PIN);
    localStorage.removeItem(PwaLocalStorageKey.AUTH_ACTIVE);
    localStorage.removeItem(PwaLocalStorageKey.AUTH_TOKEN_LIST);

    return {
      ...state,
      active: undefined,
      pin: undefined,
      onboarding: false,
      token: [],
      unlocked: false
    };
  },
  addToken: (id: number, name: string, token: string) => (state) => {
    const array = [
      ...state.token,
      {
        id,
        name,
        token
      }
    ];

    localStorage.setItem(PwaLocalStorageKey.AUTH_ACTIVE, String(id));
    localStorage.setItem(PwaLocalStorageKey.AUTH_TOKEN_LIST, JSON.stringify(array));

    return {
      ...state,
      active: id,
      token: array
    };
  },
  setActive: (id: number) => (state) => {
    if (!state.token.some((token) => token.id === id)) {
      return;
    }

    localStorage.setItem(PwaLocalStorageKey.AUTH_ACTIVE, String(id));

    return {
      ...state,
      active: id
    };
  },
  setUnlocked: (unlocked = true) => (state) => ({
    ...state,
    unlocked
  }),
  setOnboarding: (onboarding = true) => (state) => ({
    ...state,
    onboarding
  })
});
