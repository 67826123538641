import styled from '@emotion/styled/macro';
import React from 'react';

type Props = {
  title: string;
  content: string | number;
};

export function ProfileEntry({ title, content }: Props) {
  return (
    <StyledEntry>
      <Title>{title}</Title>
      <span>{content}</span>
    </StyledEntry>
  );
}

const StyledEntry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: .7rem 0;
`;

const Title = styled.span`
  font-weight: bold;
`;
