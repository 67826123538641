import styled from '@emotion/styled/macro';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type Props = {
  message?: string,
  icon?: IconDefinition
};


export function ErrorFallback({ message, icon }: Props) {
  const { t } = useTranslation()
 
  return (
    <StyledError>
      <FontAwesomeIcon icon={ icon || faWifi as IconDefinition }  size="2x"/>
      <StyledMessage>
        {message || t('home.Error')}
      </StyledMessage>
    </StyledError>
  );
}

const StyledError = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, .25);
  background-color: white;
  z-index: 25;
`;

const StyledMessage = styled.span`
  display: inline-grid;
  margin-top: 1rem;
  line-height: 1;
`;
