import styled from '@emotion/styled/macro';
import { useField } from 'formik';
import React from 'react';
import { ValueProps } from '../types';

export function ScoreValue({ question, disabled = false }: ValueProps) {
	const [{ value }, { error, touched }, { setValue }] = useField(String(question.id));

	// removed empty line when no description are there (see yes/no)
	let drawInfoText = false;
	if (question.possibleAnswerValues) {
		question.possibleAnswerValues.forEach((o) => {
			if (o.description !== '') {
				drawInfoText = true;
			}
		});
	}
	let singleInfoMessage = true; // default
	if (question.answerType.argument && !question.answerType.argument?.singleInfoMessage) {
		singleInfoMessage = false;
	}

	return (
		<StyledWrapper>
			<StyledScore data-disabled={disabled} data-error={Boolean(touched && error)}>
				<StyledLabel>
					{question.question}
				</StyledLabel>
				<StyledLabel>
					{question.textHelp}
				</StyledLabel>
				<StyledAnswers>
					{question.possibleAnswerValues && question.possibleAnswerValues.map((answerValue) => (
						<StyledAnswer
							key={answerValue.id}
							type="button"
							foreground={answerValue.colorFg || '#000000'}
							background={answerValue.colorBg || '#f1f1f1'}
							onClick={() => !disabled && setValue(answerValue.id)}
							data-selected={value === answerValue.id}
						>
							{answerValue.label === ''?answerValue.value:answerValue.label}
						</StyledAnswer>
					))}
				</StyledAnswers>
				{!singleInfoMessage && drawInfoText && (
					<StyledLabel>
						<StyledValue>
							{question.possibleAnswerValues && question.possibleAnswerValues[0]?.description}
						</StyledValue>
						<StyledValue>
							{question.possibleAnswerValues && question.possibleAnswerValues[question.possibleAnswerValues.length - 1]?.description}
						</StyledValue>
					</StyledLabel>
				)}
				{singleInfoMessage && drawInfoText && (
					<StyledLabel>
						<StyledValue>&nbsp;</StyledValue>
						<StyledValue>
							{question.possibleAnswerValues && question.possibleAnswerValues.find((answer) => answer.id === value)?.description}
						</StyledValue>
						<StyledValue>&nbsp;</StyledValue>
					</StyledLabel>
				)}
			</StyledScore>
			{touched && error && (
				<StyledError>
					{error}
				</StyledError>
			)}
		</StyledWrapper>
	);
}

const StyledWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledScore = styled.div`
  /* padding: .5rem; */
  /* background-color: #f1f1f1; */
  /* border-radius: .5rem; */
  &[data-error="true"] {
    box-shadow: 0 0 0 2px tomato;
  }
  &[data-disabled="true"] {
    pointer-events: none;
  }
`;

const StyledLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
`;

const StyledValue = styled.span`
  opacity: .25;
`;

const StyledAnswers = styled.div`
  display: flex;
`;

const StyledAnswer = styled.button<{ foreground: string, background: string }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  margin: 1px;
  color: ${(props) => props.foreground};
  background-color: ${(props) => props.background};
  opacity: .65;
  transition: opacity .25s ease, transform .25s ease;
  :first-of-type {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
  }
  :last-child {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
  }
  &[data-selected="true"] {
    /* transform: scale(1.05); */
    /* z-index: 1; */
    opacity: 1;
    box-shadow: 0 0 0 1px black;
    font-weight: bold;
  }
`;

const StyledError = styled.div`
  position: relative;
  margin-top: -1px;
  padding: .5rem .75rem;
  color: white;
  background-color: tomato;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  box-shadow: 0 -2px 0 2px tomato;
  z-index: -1;
`;
