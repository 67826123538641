import { createDakpan } from 'dakpan';

type State = {
  consent: boolean
};

export const [ConsentProvider, useConsent] = createDakpan<State>({
  consent: Boolean(localStorage.getItem('consent'))
})({
  setContent: () => () => {
    localStorage.setItem('consent', 'true');

    return {
      consent: true
    };
  }
});
