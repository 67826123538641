/* tslint:disable */
/* eslint-disable */

export interface AddressDto {
    id?: number;
    refId: string;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    gpsLongitude?: number;
    gpsLatitude?: number;
}

export interface AnswerHolderDto {
    answerValues: AnswerValueDto[];
}

export interface AnswerTypeDto {
    argument?: { [index: string]: Serializable };
    type: AnswerTypeType;
}

export interface AnswerValueDto {
    participantEntryDetailId?: number;
    id?: number;
    value?: string;
    label?: string;
    description?: string;
    colorFg?: string;
    colorBg?: string;
}

export interface BaseParticipantDto {
    id?: number;
    studyId?: number;
    refId?: string;
    groupRefId?: string;
    name?: string;
    email?: string;
    phone?: string;
    gender?: string;
    customData?: CustomDataDto;
    startDate?: DateAsString;
    endDate?: DateAsString;
    status?: ParticipantStatus;
    studyParticipationTypeId?: number;
    exportable?: boolean;
}

export interface CustomDataDto {
    field: { [index: string]: Serializable };
}

export interface CustomFormActionDto {
    code: string;
    label: string;
    description: string;
    icon: string;
    actionRight: string;
    linkCode: string;
    linkParameter: string;
    linkTargetParameter: string;
    ordering: number;
}

export interface CustomFormDto {
    appModelCode: string;
    refId: string;
    name: string;
    slug: string;
    description: string;
    active: boolean;
    fields: CustomFormFieldDto[];
    actions: CustomFormActionDto[];
}

export interface CustomFormFieldDto {
    type: CustomFormFieldType;
    property: string;
    label: string;
    placeHolder: string;
    description: string;
    defaultValue: string;
    pattern: string;
    minValue: string;
    maxValue: string;
    minDateDays: string;
    required: boolean;
    editable: boolean;
    creatable: boolean;
    visible: boolean;
    editableRight: string;
    creatableRight: string;
    visibleRight: string;
    defaultValueProperty: string;
    options: CustomFormFieldOptionDto[];
}

export interface CustomFormFieldOptionDto {
    value: string;
    label: string;
    ordering: number;
}

export interface EpisodeDto {
    participantStateId: number;
    name: string;
    onOff: boolean;
    colorFg: string;
    colorBg: string;
}

export interface NotificationDto {
    id: number;
    date: DateAsString;
    text: string;
}

export interface QuestionDependencyDto {
    id: number;
    questionId: number;
    dependencyQuestionId: number;
    answerValueId?: number;
    answerValue?: string;
    answerValueMatch?: QuestionAnswerValueMatch;
}

export interface QuestionDto {
    id: number;
    order: number;
    question: string;
    optional: boolean;
    singleFormStep: boolean;
    answerType: AnswerTypeDto;
    answerTypeType: AnswerTypeType;
    textHelp?: string;
    textPlaceholder?: string;
    answer?: AnswerHolderDto;
    possibleAnswerValues?: AnswerValueDto[];
    subQuestionDto?: SubQuestionDto;
    questionCategory?: string;
    dependencies?: QuestionDependencyDto[];
}

export interface QuestionnaireDto {
    title: string;
    questionnaireId: number;
    participantEntryId?: number;
    questionDtoList: QuestionDto[];
    episodeDto: EpisodeDto;
    episodeDtoList: EpisodeDto[];
    refDate: DateAsString;
    fillDate?: DateAsString;
    createDate: DateAsString;
}

export interface SendAnswersDto {
    questionId: number;
    answerValues: AnswerValueDto[];
}

export interface SubQuestionDto {
    parentAnswerValue: string;
    questionDto: QuestionDto;
}

export interface AbstractBaseRq {
}

export interface AbstractBaseRs {
    status: ResponseStatus;
    code: string;
}

export interface BaseRs extends AbstractBaseRs {
}

export interface SecurityChangePasswordRq extends AbstractBaseRq {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface SecurityChangePasswordRs extends AbstractBaseRs {
    message: string;
}

export interface SecurityForgotPasswordRq extends AbstractBaseRq {
    email: string;
}

export interface SecurityForgotPasswordRs extends AbstractBaseRs {
}

export interface SecurityRefreshTokenRq extends AbstractBaseRq {
    refreshSequence: number;
}

export interface SecurityRefreshTokenRs extends AbstractBaseRs {
    token: string;
    refreshSequence: number;
}

export interface ServerForbiddenRs extends AbstractBaseRs {
}

export interface ServerInternalErrorRs extends AbstractBaseRs {
}

export interface ServerUnauthenticatedRs extends AbstractBaseRs {
}

export interface ContactDetailDto {
    id: number;
    addressDto: AddressDto;
    contactPersonName: string;
    email: string;
    phone: string;
    website: string;
    studySiteName: string;
}

export interface InformationItemDto {
    id: number;
    type: InformationItemType;
    title: string;
    description: string;
}

export interface ParticipantProfileDetailDto extends BaseParticipantDto {
}

export interface ParticipationDto {
    id: number;
    name: string;
    mainAccount: boolean;
    studyId: number;
    studyName: string;
}

export interface TimelineItemDto {
    id: number;
    title: string;
    titleCaption: string;
    text: string;
    date: DateAsString;
    status: ControlStatus;
    controlType: ControlType;
    questionnaireDto: QuestionnaireDto;
    timelinePostDone: boolean;
    timelineAutoNext: boolean;
    timelineColor: string;
    timelineCaption: string;
}

export interface AuthRq extends AbstractBaseRq {
    username: string;
    password: string;
    appCode?: string;
    deviceToken: string;
}

export interface AuthRs extends AbstractBaseRs {
    token: string;
}

export interface ContactRs extends AbstractBaseRs {
    contactDetail: ContactDetailDto;
}

export interface DownloadFileRq extends AbstractBaseRq {
    fileId: number;
    timeLineItemId: number;
}

export interface GetCustomFormRq extends AbstractBaseRq {
    key: string;
}

export interface GetCustomFormRs extends AbstractBaseRs {
    form: CustomFormDto;
}

export interface GetInfoDetailsRq extends AbstractBaseRq {
    id: number;
}

export interface GetInfoDetailsRs extends AbstractBaseRs {
    details: string;
}

export interface InformationListRs extends AbstractBaseRs {
    items: InformationItemDto[];
}

export interface NotificationsByLimitRq extends AbstractBaseRq {
    page: number;
    size: number;
}

export interface NotificationsByLimitRs extends AbstractBaseRs {
    notificationList: NotificationDto[];
}

export interface ParticipantProfileDetailRs extends AbstractBaseRs {
    participantProfileDetail: ParticipantProfileDetailDto;
    endCalendar: TimelineItemDto;
}

export interface ParticipationListRs extends AbstractBaseRs {
    participations: ParticipationDto[];
}

export interface RefreshTokenRq extends AbstractBaseRq {
    appCode: string;
}

export interface SendAnswersRq extends AbstractBaseRq {
    timelineItemId: number;
    answerList: SendAnswersDto[];
}

export interface SendAnswersRs extends AbstractBaseRs {
}

export interface SendSmsCodeRq extends AbstractBaseRq {
    pinCode: number;
}

export interface SendSmsCodeRs extends AbstractBaseRs {
}

export interface SetSeenRq extends AbstractBaseRq {
    timelineItemId: number;
}

export interface SetSeenRs extends AbstractBaseRs {
}

export interface TimelineByLimitRq extends AbstractBaseRq {
    page: number;
    size: number;
}

export interface TimelineByLimitRs extends AbstractBaseRs {
    daysToFillDiary: number;
    startCalendar: TimelineItemDto;
    items: TimelineItemDto[];
}

export interface UploadFileRs extends AbstractBaseRs {
    fileId: number;
}

export interface Serializable {
}

export type DateAsString = string;

export enum AnswerTypeType {
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    SCORELIST = "SCORELIST",
    DROPDOWN = "DROPDOWN",
    MULTISELECT = "MULTISELECT",
    TEXT = "TEXT",
    CRON = "CRON",
    READONLY = "READONLY",
    UPLOAD_IMAGE = "UPLOAD_IMAGE",
    UPLOAD_VIDEO = "UPLOAD_VIDEO",
    UPLOAD_AUDIO = "UPLOAD_AUDIO",
    UPLOAD_FILE = "UPLOAD_FILE",
}

export enum ParticipantStatus {
    WAIT_REG = "WAIT_REG",
    WAIT = "WAIT",
    OPEN = "OPEN",
    OPEN_END = "OPEN_END",
    DONE = "DONE",
    ARCHIVED = "ARCHIVED",
}

export enum CustomFormFieldType {
    TEXT = "TEXT",
    TEXT_EMAIL = "TEXT_EMAIL",
    NUMBER = "NUMBER",
    NUMBER_PHONE = "NUMBER_PHONE",
    DATE = "DATE",
    SELECT = "SELECT",
    BOOLEAN = "BOOLEAN",
    TAB = "TAB",
    TEXT_READONLY = "TEXT_READONLY",
    COLOR = "COLOR",
}

export enum QuestionAnswerValueMatch {
    EXACT = "EXACT",
    CASE_INSENSITIVE = "CASE_INSENSITIVE",
    REGEX = "REGEX",
    CONTAINS = "CONTAINS",
    GREATER_THAN = "GREATER_THAN",
    LESSER_THAN = "LESSER_THAN",
}

export enum ResponseStatus {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}

export enum InformationItemType {
    INFO = "INFO",
    FAQ = "FAQ",
    META = "META",
}

export enum ControlStatus {
    DONE = "DONE",
    SEEN = "SEEN",
    CLOSED = "CLOSED",
    NEW = "NEW",
    HIDDEN = "HIDDEN",
    TIMEOUT = "TIMEOUT",
    REMOVED = "REMOVED",
    FAILURE = "FAILURE",
}

export enum ControlType {
    DIARY = "DIARY",
    SCORELIST = "SCORELIST",
    QUESTIONNAIRE = "QUESTIONNAIRE",
    MESSAGE = "MESSAGE",
    TASK = "TASK",
    START = "START",
    END = "END",
    ZIKA_START_CALENDAR = "ZIKA_START_CALENDAR",
    ZIKA_END_CALENDAR = "ZIKA_END_CALENDAR",
    TRIGGER = "TRIGGER",
    REPORT = "REPORT",
    NOTIFICATION = "NOTIFICATION",
    EMAIL = "EMAIL",
    SMS = "SMS",
}
