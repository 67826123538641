import styled from '@emotion/styled/macro';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { bool, object } from 'yup';

import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { Form } from '../components/Form';
import { Page } from '../components/Page';
import { useFetch } from '../hooks/useFetch';

type Values = {
  accept: boolean
};

export function Disclaimer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, error, loading] = useFetch<{ details: string }>('/mobile/getInfoDetailAccept');
  const formik = useFormik<Values>({
    initialValues: {
      accept: false
    },
    validationSchema: object<Values>({
      accept: bool().oneOf([true])
    }),
    onSubmit: () => navigate('./../pin')
  });

  return (
    <Page title={t('registration.accept-terms.title')} padding={true}>
      <StyledDisclaimer>
        <StyledHeading>
          {t('registration.accept-terms.disclaimer')}
        </StyledHeading>
        {error?.code || data?.details}
      </StyledDisclaimer>
      <StyledBottom>
        <Form formik={formik}>
          <Checkbox name="accept" label={t('registration.accept-terms.switch')}/>
          <Button
            text={t('registration.accept-terms.button')}
            type="submit"
            disabled={!formik.values.accept || loading}
          />
        </Form>
      </StyledBottom>
    </Page>
  );
}

const StyledHeading = styled.h2`
  margin-bottom: 1rem;
`;

const StyledDisclaimer = styled.div`
  text-align: justify;
  margin-bottom: 8.5rem;
`;

const StyledBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem 1rem;
  background-color: white;
  box-shadow: 0 0 2.5rem 2.5rem white;
`;
