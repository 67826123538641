import styled from '@emotion/styled/macro';
import { useFormik } from 'formik';
import React, { createElement, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { mixed, object } from 'yup';

import { answerMatchers, answerValues, Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { ControlType, QuestionDto, TimelineItemDto, AnswerTypeType, ControlStatus } from '../messages-pwa';

import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Form } from './Form';
import { Dialog } from '../components/Dialog';
import { useNextItem } from '../states/nextitem';
import { useBackClick } from '../states/backclick';

type Props = {
	control: TimelineItemDto;
};

export function DiaryControl({ control: { id, text, controlType, questionnaireDto, status, timelinePostDone, timelineAutoNext } }: Props) {
	const { t } = useTranslation();
	const [, { setNextItem }] = useNextItem();
	const [dialogShow, setDialogShow] = useState(false);
	const [dialogDone, setDialogDone] = useState(!timelinePostDone);
	const [, { setBackClick }] = useBackClick();
	const [data, error, loading, execute] = useFetch('/mobile/sendAnswers', Method.Post);

	const getQuestionInitial = (question: QuestionDto) => {
		let answer = answerValues[question.answerTypeType][1];

		if (question.answer?.answerValues.length) {
			const arr = question.answer.answerValues.map((o) => {
				const value: unknown = o.id || o.value;

				return question.answerTypeType === AnswerTypeType.BOOLEAN ? value === '1' : value;
			});

			answer = question.answerTypeType === AnswerTypeType.MULTISELECT ? arr : arr[0];
		}

		return {
			[question.id]: answer,
			...!question.subQuestionDto ? {} : getQuestionInitial(question.subQuestionDto.questionDto)
		};
	};

	const checkDependencies = (question: QuestionDto) => {
		if (controlType === ControlType.TASK) {
			return true; // TODO: remove later when official support for deps in tasks is enabled.
		}
		if (controlType === ControlType.SCORELIST) {
			return true; // TODO: remove later when official support for deps in scorelist is enabled.
		}
		if (question.dependencies == null || question.dependencies.length === 0) {
			return true; // no deps is match true
		}
		return question.dependencies.some((dep) => {
			let values = formik.values[String(dep.dependencyQuestionId)];
			if (values === undefined) {
				return false; // no value, then dep can't match'
			}
			if (!Array.isArray(values)) {
				values = [values]; // wrap all data types into array
			}
			return values.some((valueForm) => {
				let value = valueForm;
				let wanted = dep.answerValue;
				if (dep.answerValueId != null) {
					return String(value) === String(dep.answerValueId);
				} else {
					// magic boolean parsing, as internal boolean is used but in api+db 1/0 fixme: try to get to true|false valuas always
					let depQuestionDto = questionnaireDto.questionDtoList.find((q) => String(q.id) === String(dep.dependencyQuestionId));
					if (depQuestionDto && depQuestionDto.answerTypeType === AnswerTypeType.BOOLEAN) {
						value = String(valueForm) === 'true' ? '1' : '0';
					}
					return dep.answerValueMatch && answerMatchers[dep.answerValueMatch](value, wanted);
				}
			});
		});
	};

	const formik = useFormik({
		initialValues: questionnaireDto.questionDtoList.reduce((previous, current) => ({
			...previous,
			...getQuestionInitial(current)
		}), {}),
		validationSchema: object(questionnaireDto.questionDtoList.reduce((previous, current) => {
			const initial = answerValues[current.answerTypeType][1];

			if (initial === undefined) {
				return previous;
			}

			return {
				...previous,
				...current.optional ? {} : {
					[current.id]: mixed().when([], (schema) => {
						if (!checkDependencies(current)) {
							return schema;
						}

						return schema.required(t('form.error.required'));
					})
				}
			};
		}, {})),

		onSubmit: (values) => {
			setDialogShow(timelinePostDone);
			setNextItem(timelineAutoNext);
			setBackClick(true);
			execute({
				answerList: Object.keys(values).reduce<unknown[]>((previous, current) => {
					let value = values[current];
					const question = questionnaireDto.questionDtoList.find((q) => String(q.id) === current);

					if (!question
						|| value === undefined
						|| value === ''
						|| value === String(question.id)
						|| !checkDependencies(question)) {
						return previous;
					} else if (!Array.isArray(value)) {
						value = [value];
					}
					let isOptionAnswer = false;
					if (question.answerTypeType === AnswerTypeType.DROPDOWN || question.answerTypeType === AnswerTypeType.SCORELIST || question.answerTypeType === AnswerTypeType.MULTISELECT) {
						isOptionAnswer = true;
					}
					return [
						...previous,
						{
							questionId: question.id,
							answerValues: value.map((v) => isOptionAnswer ? {
								id: v
							} : {
									value: typeof v === 'boolean' ? v ? '1' : '0' : v
								})
						}
					];
				}, []),
				timelineItemId: id
			});
		}
	});

	const editable = status === ControlStatus.NEW || status === ControlStatus.SEEN;

	const renderQuestion = (question: QuestionDto) => {
		if (!checkDependencies(question)) {
			return null;
		}

		const config = answerValues[question.answerTypeType];

		return (
			<Fragment key={question.id}>
				{question.question && config[2] && (
					<StyledQuestion>
						{question.question}
					</StyledQuestion>
				)}
				{config[0] && createElement(config[0], {
					question,
					timelineItemId: id,
					disabled: !editable,
					min: Number(question.answerType.argument?.minLength || 0),
					max: Number(question.answerType.argument?.maxLength || 1000),
					pattern: question.answerType.argument?.pattern ? String(question.answerType.argument?.pattern) : undefined
				})}
				{question.subQuestionDto && renderQuestion(question.subQuestionDto.questionDto)}
			</Fragment>
		);
	};

	return data && dialogDone === true ? (
		<Navigate to="./../dashboard" />
	) : (
			<Form formik={formik}>
				{text && (
					<StyledTitle>
						{text}
					</StyledTitle>
				)}
				{questionnaireDto.questionDtoList.map(renderQuestion)}
				{controlType === ControlType.DIARY && (
					<Checkbox
						name="checkAll"
						label={t('form.noneOfAbove')}
						checked={Object.keys(formik.values)
							.reduce<boolean>((previous, current) => previous && !formik.values[current], true)}
						onChange={() => formik.resetForm()}
					/>
				)}
				{editable && (
					<StyledButton text={t('form.submit')} type="submit" disabled={loading} />
				)}
				{timelinePostDone && (<Dialog open={dialogShow}>
					<StyledHeading>
						{t('questionaire.success')}
					</StyledHeading>
					{t('questionaire.message1')} {t('questionaire.message2')}
					<StyledButtons>
						<Button text={t('questionaire.okButton')} onClick={() => {
							setDialogDone(true);
						}} />
					</StyledButtons>
				</Dialog>)}
				{error && (
					<StyledError>
						{t('questionaire.cannotSave')}
					</StyledError>
				)}
			</Form>
		);
}

const StyledTitle = styled.div`
  position: relative;
  margin: 2.5rem 0 1.5rem;
  ::before {
    content: "";
    position: absolute;
    display: block;
    top: -1.25rem;
    left: calc(50% - 1rem);
    width: 2rem;
    height: .25rem;
    background-color: #f1f1f1;
  }
`;

const StyledQuestion = styled(StyledTitle)`
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const StyledError = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const StyledHeading = styled.h3`
  margin-bottom: 1rem;
`;

const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
`;
