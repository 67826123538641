import styled from '@emotion/styled/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentPropsWithoutRef } from 'react';

type Props = {
  text?: string,
  icon?: IconDefinition
};

export function Button({ text, icon, ...props }: Props & ComponentPropsWithoutRef<'button'>) {
  return (
    <StyledButton {...props}>
      {icon && (
        <StyledIcon icon={icon} data-margin={Boolean(text)}/>
      )}
      {text}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  width: 100%;
  padding: .5rem .75rem;
  color: white;
  background-color: dodgerblue;
  border-radius: .5rem;
  text-align: center;
  transition: transform .25s ease, opacity .25s ease;
  :active {
    transform: scale(.95);
    opacity: .75;
  }
  :disabled {
    opacity: .5;
    pointer-events: none;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  &[data-margin="true"] {
    margin-right: .5rem;
  }
`;
