import { createDakpan } from "dakpan";

type State = {
	backclick: boolean;
};

export const [BackItemProvider, useBackClick] = createDakpan<State>({
	backclick: Boolean(sessionStorage.getItem("backClick") || false)
})({
	setBackClick: (flag: boolean) => () => {
		sessionStorage.setItem("backClick", ""+flag);
		return {
			backclick: flag
		};
	}
});