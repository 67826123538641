import styled from '@emotion/styled/macro';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../states/auth';
import { object, string } from 'yup';

import { Button } from '../components/Button';
import { Form } from '../components/Form';
import { Input } from '../components/Input';
import { Page } from '../components/Page';
import { useNavigate } from 'react-router';
import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { GetCustomFormRs } from '../messages-pwa';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { type } from '../utils/type';

type Values = {
	currentPassword: string,
	newPassword: string,
	confirmPassword: string
};

export function ChangePassword() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [, { logout }] = useAuth();
	const [error, setError] = useState<string | undefined>('');
	const [, , , save] = useFetch<GetCustomFormRs>('/mobile/changePassword', Method.Post);

	const formTypes: Record<string, [string, any]> = {
		PASSWORD: ['password', string()],
	};

	const formik = useFormik<Values>({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: ''
		},
		validationSchema: object<Values>({
			currentPassword: string().required('Required field').min(4),
			newPassword: string().required('Required field').min(4),
			confirmPassword: string().required('Required field').min(4)
		}),
		onSubmit: (values) => {
			save(values).then((res) => {
				if (!type<Response>(res, 'status', 'SUCCESS')) {
					setError(res?.message);
				} else {
					setError(undefined);
					logout();
				}
			});
		}
	});

	useEffect(() => {
	}, [error]);

	return (
		<StyledPage
			title={t('main.change-password.title')}
			actionIcon={{
				onClick: () => { navigate('../profile') },
				icon: faArrowLeft as IconDefinition
			}}
		>
			<Form formik={formik}>
				<Input
					id="currentPassword"
					name="currentPassword"
					type={formTypes['PASSWORD'][0]}
					label={t('main.change-password.currentPassword')}
					onChange={formik.handleChange}
					value={formik.values.currentPassword}
				/>
				<Input
					id="newPassword"
					name="newPassword"
					type={formTypes['PASSWORD'][0]}
					label={t('main.change-password.newPassword')}
					onChange={formik.handleChange}
					value={formik.values.newPassword}
				/>
				<Input
					id="confirmPassword"
					name="confirmPassword"
					type={formTypes['PASSWORD'][0]}
					label={t('main.change-password.confirmPassword')}
					onChange={formik.handleChange}
					value={formik.values.confirmPassword}
				/>
				<Button type="submit" text={t('main.change-password.button')}></Button>
			</Form>
			{error && (
				<StyledError>
					{error}
				</StyledError>
			)}
		</StyledPage>
	);
}

const StyledPage = styled(Page)`
  justify-content: space-between;
`;

const StyledError = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

