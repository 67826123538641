import { useCallback, useEffect, useState } from 'react';

import { api, Method } from '../constants';
import { useAuth } from '../states/auth';
import { Response } from '../types';
import { ResponseStatus } from '../messages-pwa';

type Result<T> = [
	Response<T> | undefined,
	Response | undefined,
	boolean,
	(body?: object, override?: string) => Promise<Response<T | {}> | undefined>
];
let cacheAccountActive: number | undefined;
const cacheObject = {};
let cacheRefreshTime = 0;
export function useFetch<T>(url?: string, method = Method.Get, auto = true, auth = true, cache = false): Result<T> {
	const [{ active, token }, { logout }] = useAuth();
	const [data, setData] = useState<Response<T>>();
	const [error, setError] = useState<Response>()
	const [loading, setLoading] = useState(false);
	const execute = useCallback((body?: object, override?: string) => {
		if (!url) {
			return Promise.resolve(undefined);
		}
		setData(undefined);
		setError(undefined);
		setLoading(true);
		if (cache && cacheObject[url] && new Date().getTime() - cacheRefreshTime < 1000 * 60 * 3 && cacheAccountActive === active) {
			setLoading(false);
			setData(cacheObject[url]);
			return Promise.resolve(cacheObject[url]);
		}

		return fetch(`${api}${url}`, {
			method,
			headers: {
				'content-type': 'application/json',
				...!active && !override ? {} : {
					authorization: `Bearer ${override ?? token.find((t) => t.id === active)?.token}`
				}
			},
			credentials: override !== undefined ? 'include' : 'omit',
			body: JSON.stringify(body)
		}).then((response) => Promise.all([
			response.ok,
			response.headers,
			response.text()
		])).then<Response<T | {}>>(([ok, headers, text]) => {
			try {
				return {
					...JSON.parse(text),
					headers
				};
			} catch {
				return {
					status: ok ? 'SUCCESS' : 'FAILED',
					code: '',
					headers
				};
			}
		}).then((response) => {
			setLoading(false);

			if (response.code === 'Unauthorized' && auth) {
				logout();
			}

			if (response.status === 'SUCCESS') {
				if (cache) {
					cacheObject[url] = response as Response<T>;
					cacheRefreshTime = new Date().getTime();
					cacheAccountActive = active;
				}
				setData(response as Response<T>);
			} else {
				setError(response);
			}

			return response;
		}).catch((e) => {
			console.log(e);

			const response: Response = {
				status: ResponseStatus.FAILED,
				code: '',
				message: '',
				headers: new Headers()
			};

			setLoading(false);
			setError(response);

			return response;
		});
	}, [url, method, active, auth, token, setData, setLoading, logout, cache]);

	useEffect(() => {
		if (method === Method.Get && auto) {
			execute();
		}
	}, [execute, method, auto]);

	return [data, error, loading, execute];
}
