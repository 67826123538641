import styled from '@emotion/styled/macro';
import { useField } from 'formik';
import React from 'react';

type Props = {
  name: string,
  label: string,
  disabled?: boolean,
  value?: number,
  checked?: boolean,
  onChange?: (checked: boolean) => void
};

export function Checkbox({ name, label, disabled = false, value, checked, onChange }: Props) {
  const [field, , { setValue }] = useField(name);

  const id = `${name}-${value === undefined ? '' : `-${value}`}`;

  return (
    <StyledWrapper data-disabled={disabled}>
      <StyledInput
        id={id}
        type="checkbox"
        checked={checked === undefined ? value ? field.value.indexOf(value) !== -1 : field.value : checked}
        {...field}
        onChange={(e) => {
          if (onChange) {
            return onChange(checked === undefined ? !field.value : !checked);
          } else if (value === undefined) {
            return field.onChange(e);
          }

          setValue(field.value.indexOf(value) === -1 ? [
            ...field.value,
            value
          ] : field.value.filter((v) => v !== value));
        }}
      />
      <StyledLabel htmlFor={id}>
        {label}
      </StyledLabel>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: .5rem;
  &[data-disabled="true"] {
    pointer-events: none;
  }
`;

const StyledInput = styled.input`
  display: none;
  :checked ~ label {
    ::before {
      background-color: dodgerblue;
    }
    ::after {
      border-color: white;
    }
  }
`;

const StyledLabel = styled.label`
  display: block;
  padding: .5rem .75rem .5rem 2.5rem;
  background-color: #f1f1f1;
  border-radius: .5rem;
  :hover {
    cursor: pointer;
  }
  ::before, ::after {
    content: "";
    position: absolute;
    display: block;
  }
  ::before {
    top: .5rem;
    left: .75rem;
    width: 1.1rem;
    height: 1.1rem;
    background-color: #d0d0d0;
    border-radius: .25rem;
    transition: background-color .25s ease;
  }
  ::after {
    top: .65rem;
    left: 1.1rem;
    width: .4rem;
    height: .7rem;
    border: 3px solid transparent;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg);
    transition: border .25s ease;
  }
`;
