import { Locale } from 'date-fns';
import { enUS, es, nl, de } from 'date-fns/locale';
import { ComponentType } from 'react';

import { BooleanValue } from './components/BooleanValue';
import { DateValue } from './components/DateValue';
import { DropdownValue } from './components/DropdownValue';
import { MultiSelectValue } from './components/MultiSelectValue';
import { NumberValue } from './components/NumberValue';
import { ReadonlyValue } from './components/ReadonlyValue';
import { ScoreValue } from './components/ScoreValue';
import { TextValue } from './components/TextValue';
import { FileValue } from './components/FileValue'
import english from './locales/en.json';
import spanish from './locales/es.json';
import dutch from './locales/nl.json';
import germany from './locales/de.json';
import { ValueProps } from './types';
import { ControlType, QuestionAnswerValueMatch, AnswerTypeType } from './messages-pwa';

const name = process.env.REACT_APP_APP;
const env = process.env.REACT_APP_ENV;

const config = require(`./environments/${name ? `${name}-${env}` : process.env.NODE_ENV}`).default;

export const baseurl = '/' + process.env.PUBLIC_URL; // TODO: why do js dev NEVER do relative path correctly .... sic

export const app = config.app;
export const api = config.api;
export const locales: Record<string, [object, Locale]> = {
  en: [english, enUS],
  nl: [dutch, nl],
  es: [spanish, es],
  de: [germany, de]
};

export enum PwaLocalStorageKey {
    AUTH_PIN = "PWA_PIN",
    AUTH_ACTIVE = "PWA_ACTIVE",
    AUTH_TOKEN_LIST = "PWA_TOKEN_LIST", // rename to TOKEN when is normalized
    //AUTH?_ACCOUNT = "PWA_ACCOUNT", // TODO: write token into normal ...
}

export enum PwaSessionStorageKey {
}

export enum Method {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete'
}

// DEPRECATED: [0]color is not used anymore, and [1]blocked will me moved asp to db then we drop this const.
export const controlValues: Record<ControlType, [string, boolean]> = {
  [ControlType.START]: ['green', false],
  [ControlType.DIARY]: ['dodgerblue', true],
  [ControlType.QUESTIONNAIRE]: ['darkcyan', true],
  [ControlType.SCORELIST]: ['darkorange', false],
  [ControlType.TASK]: ['gold', false], 
  [ControlType.MESSAGE]: ['darkgray', false],
  [ControlType.END]: ['green', false],
  // just added for mapping purpose
  [ControlType.ZIKA_START_CALENDAR]: ['green', false],
  [ControlType.ZIKA_END_CALENDAR]: ['green', false],
  [ControlType.SMS]: ['green', false],
  [ControlType.REPORT]: ['green', false],
  [ControlType.TRIGGER]: ['green', false],
  [ControlType.EMAIL]: ['green', false], 
  [ControlType.NOTIFICATION]: ['green', false]
};


export const answerValues: Record<AnswerTypeType, [ComponentType<ValueProps> | undefined, any, boolean]> = {
  [AnswerTypeType.BOOLEAN]: [BooleanValue, false, false],
  [AnswerTypeType.SCORELIST]: [ScoreValue, '', false],
  [AnswerTypeType.DROPDOWN]: [DropdownValue, '', true],
  [AnswerTypeType.TEXT]: [TextValue, '', true],
  [AnswerTypeType.MULTISELECT]: [MultiSelectValue, [], true],
  [AnswerTypeType.DATE]: [DateValue, '', true],
  [AnswerTypeType.NUMBER]: [NumberValue, '', true],
  [AnswerTypeType.READONLY]: [ReadonlyValue, undefined, false],
  // just added for mapping purpose
  [AnswerTypeType.CRON]: [ReadonlyValue, undefined, false],
  [AnswerTypeType.UPLOAD_AUDIO]: [ReadonlyValue, undefined, true],
  [AnswerTypeType.UPLOAD_FILE]: [FileValue, undefined, true], 
  [AnswerTypeType.UPLOAD_VIDEO]: [ReadonlyValue, undefined, true],
  [AnswerTypeType.UPLOAD_IMAGE]: [FileValue, undefined, true] 
};

export const answerMatchers: Record<QuestionAnswerValueMatch, (value: any, wanted: any) => boolean> = {
  [QuestionAnswerValueMatch.EXACT]: (value, wanted) => String(value) === String(wanted),
  [QuestionAnswerValueMatch.CASE_INSENSITIVE]: (value, wanted) => String(value).toLowerCase() === String(wanted).toLowerCase(),
  [QuestionAnswerValueMatch.REGEX]: (value, wanted) => new RegExp(String(wanted)).test(String(value)),
  [QuestionAnswerValueMatch.CONTAINS]: (value, wanted) => String(value).indexOf(String(wanted)) !== -1,
  [QuestionAnswerValueMatch.GREATER_THAN]: (value, wanted) => Number(value) > Number(wanted),
  [QuestionAnswerValueMatch.LESSER_THAN]: (value, wanted) => Number(value) < Number(wanted)
};
