import { isBefore, parseISO } from 'date-fns';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import { controlValues, Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { useAuth } from '../states/auth';
import { Response } from '../types';
import { ControlStatus, TimelineItemDto, TimelineByLimitRs } from '../messages-pwa';
import { TimelineItem } from './TimelineItem';
import { useNextItem } from '../states/nextitem';
import { useNavigate } from 'react-router';
import { useBackClick } from '../states/backclick';

type Props = {
	url: string,
	onlyLast: boolean,
	setLoading: (loading: boolean) => void,
	setError: (error: boolean) => void,
	setEmpty: (empty: boolean) => void
};

export function Timeline({ url, onlyLast, setLoading, setError, setEmpty }: Props) {
	const ref = useRef(true);
	const [{ active }] = useAuth();
	const [, error, loading, execute] = useFetch<TimelineByLimitRs>(url, Method.Post);
	const [items, setItems] = useState<Array<Omit<TimelineItemDto, 'date'> & { date: Date }>>([]);
	const [firstItem, setFirstItem] = useState<Omit<TimelineItemDto, 'date'> & { date: Date }>();
	const [page, setPage] = useState(0);
	const [more, setMore] = useState(true);
	const [{ nextitem },{ setNextItem }] = useNextItem();
	const [{ backclick }] = useBackClick();
	const navigate = useNavigate();
	const scrollRef = useInfiniteScroll<HTMLDivElement>({
		loading,
		hasNextPage: more && !error,
		onLoadMore: async () => {
			const response = await execute({
				page,
				size: 20
			});

			setLoading(false);

			if (!response || response.status === 'FAILED' || typeof response.status === 'number' || !ref.current) {
				return;
			}

			const timeline = response as Response<TimelineByLimitRs>;
			
			setFirstItem(
				timeline.items.map((item) => ({
					...item,
					date: parseISO(item.date.toString())
				}))[0]
			);
			setItems([
				...items, 
				...timeline.items.map((item) => ({
					...item,
					date: parseISO(item.date.toString())
				})).filter(({ status, date }) => status !== ControlStatus.HIDDEN && isBefore(date, new Date()))
			]);

			setPage(page + 1);
			setMore(timeline.items.length === 20);
		}
	});



	useEffect(() => {
		setEmpty(!items.length);
	}, [items, setEmpty]);

	useEffect(() => {
		setError(Boolean(error));
	}, [error, setError]);

	useLayoutEffect(() => {
		setLoading(true);
		setItems([]);
		setPage(0);
		setMore(true);
	}, [url, active, setLoading]);

	useEffect(() => () => {
		ref.current = false;
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (isMounted && firstItem && nextitem && backclick) {
			setNextItem(false);
			navigate(`./../dashboard/${firstItem.id}`, { state: firstItem });
		}
		return () => { isMounted = false }; 
	}, [firstItem, nextitem, backclick, navigate, setNextItem]);

	let blocked = false;

	return (
		<div ref={scrollRef}>
			{items.map((item) => {
				const disabled = onlyLast ? blocked : false;

				blocked = blocked || controlValues[item.controlType][1];

				return  (
					<TimelineItem key={item.id} item={item} disabled={disabled} />
				);
			})}
		</div>
	);
}
