import { css, Global } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCog, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useNavigate } from 'react-router';

import { useAuth } from '../states/auth';

import { ErrorFallback } from './ErrorFallback';
import { Tabs } from './Tabs';
import { useBackClick } from '../states/backclick';

type Props = {
	children?: ReactNode,
	title: string,
	subtitle?: string,
	padding?: boolean,
	loading?: boolean,
	error?: {
		message: string,
		icon: IconDefinition
	} | boolean,
	backIcon?: boolean,
	actionIcon?: {
		icon: IconDefinition,
		onClick: () => void
	},
	tabs?: ComponentPropsWithoutRef<typeof Tabs>
};

export function Page({
	children,
	title,
	subtitle,
	padding = false,
	loading = false,
	error = false,
	backIcon = false,
	actionIcon,
	tabs,
	...props
}: Props & ComponentPropsWithoutRef<'div'>) {
	const [{ unlocked }] = useAuth();
	const navigate = useNavigate();
	const [, { setBackClick }] = useBackClick();


	return (
		<StyledPage data-padding={padding} data-navigation={unlocked} data-tabs={Boolean(tabs)} {...props}>
			<StyledTitle>
				{title}
			</StyledTitle>
			{backIcon && (
				<StyledAction onClick={() => { navigate(-1); setBackClick(false); }}>
					<FontAwesomeIcon icon={faChevronLeft as IconDefinition} />
				</StyledAction>
			)}
			{actionIcon && (
				<StyledAction onClick={actionIcon.onClick} data-action={true}>
					<FontAwesomeIcon icon={actionIcon.icon} />
				</StyledAction>
			)}
			{tabs && (
				<Tabs {...tabs} />
			)}
			{subtitle && (
				<StyledSubtitle>
					{subtitle}
				</StyledSubtitle>
			)}
			{children}
			{typeof error !== 'boolean' && (
				<ErrorFallback {...error} />
			)}
			{loading && (
				<Global
					styles={css`
            body {
              overflow-y: hidden;
            }
          `}
				/>
			)}
			<StyledSpinner data-loading={loading}>
				<FontAwesomeIcon icon={faCog as IconDefinition} size="2x" spin={true} />
			</StyledSpinner>
		</StyledPage>
	);
}

const StyledPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 3rem;
  max-width: 35rem;
  &[data-padding="true"] {
    padding: 1rem;
  }
  &[data-navigation="true"] {
    margin-bottom: 3rem;
  }
  &[data-tabs="true"] {
    margin-top: 5.5rem;
  }
`;

const StyledTitle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  color: white;
  background-color: black;
  font-weight: bold;
  z-index: 50;
`;

const StyledAction = styled.button`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: .5rem;
  width: 2rem;
  height: 2rem;
  color: white;
  z-index: 50;
  transition: opacity .25s ease;
  :active {
    opacity: .5;
    transition: none;
  }
  :not([data-action]) {
    left: .5rem;
  }
  &[data-action] {
    right: .5rem;
  }
`;

const StyledSubtitle = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  opacity: .25;
`;

const StyledSpinner = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 25;
  transition: opacity .25s ease;
  &[data-loading="false"] {
    opacity: 0;
    pointer-events: none;
  }
`;
