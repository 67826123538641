export function unpack(obj: object) {
  const result = {};

  for (const key of Object.keys(obj)) {
    let current = result;
    const parts = key.split('.');
    const top = parts.pop()!;

    while (parts.length) {
      const part = parts.shift()!;

      current = current[part] = current[part] || {};
    }

    current[top] = obj[key];
  }

  return result;
}
