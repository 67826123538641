import React from 'react';
import { useTranslation } from 'react-i18next';

import { ValueProps } from '../types';

import { Input } from './Input';

export function DropdownValue({ question, disabled = false }: ValueProps) {
  const { t } = useTranslation();

  return (
    <Input name={String(question.id)} disabled={disabled} type="select">
      <option value="">
        {t('form.select')}
      </option>
      {question.possibleAnswerValues && question.possibleAnswerValues.map((answer) => (
        <option key={answer.id} value={answer.id}>
          {answer.label && answer.label !== '' ? answer.label : answer.value}
        </option>
      ))}
    </Input>
  );
}
