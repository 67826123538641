import styled from '@emotion/styled/macro';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { object, string } from 'yup';

import { Button } from '../components/Button';
import { Form } from '../components/Form';
import { Input } from '../components/Input';
import { Page } from '../components/Page';
import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';

type Values = {
  code: string
};

export function Verification() {
  const { t } = useTranslation();
  const [, requestError, requestLoading] = useFetch('/mobile/requestSmsCode');
  const [data, error, loading, execute] = useFetch('/mobile/sendSmsCode', Method.Post);
  const formik = useFormik<Values>({
    initialValues: {
      code: ''
    },
    validationSchema: object<Values>({
      code: string().required().min(5).max(5)
    }),
    onSubmit: ({ code }) => {
      execute({
        pinCode: code
      });
    }
  });

  return data ? (
    <Navigate to="./../disclaimer" />
  ) : (
    <Page
      title={t('registration.enter-sms-code.title')}
      subtitle={t('registration.enter-sms-code.sms-info')}
      padding={true}
    >
      <Form formik={formik}>
        <Input name="code" type="number" label={t('registration.enter-sms-code.sms-verification')}/>
        <StyledButton
          text={t('registration.login-user.button')}
          type="submit"
          disabled={Boolean(requestLoading || requestError || loading)}
        />
      </Form>
      {!requestLoading && (
        <StyledMessage>
          {t(error
            ? error.code === 'EXCEPTION_PINCODE_MISMATCH'
              ? 'registration.enter-sms-code.wrongpin'
              : error.code
            : requestError
              ? requestError.code === 'EXCEPTION_PINCODE_ALREADY_SAVED'
                ? 'registration.enter-sms-code.existingpin'
                : requestError.code
              : 'registration.enter-sms-code.info')}
        </StyledMessage>
      )}
    </Page>
  );
}

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const StyledMessage = styled.div`
  margin-top: 1rem;
  text-align: center;
`;
