import styled from '@emotion/styled/macro';
import { useField } from 'formik';
import React from 'react';
import axios from 'axios';

import { api } from '../constants'
import { useAuth } from '../states/auth';

import { useTranslation } from 'react-i18next';

import { DownloadFileRq } from '../messages-pwa';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCog, faCamera, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

type Props = {
  name: string,
  type: string,
  accept: string,
  timelineItemId: number,
  disabled?: boolean,
  value?: number,
  onChange?: (e: any) => void
};

type FileInputProps = {
  id: string,
  name: string,
  type: string,
  accept: string,
  timelineItemId: number,
  onUploadDone: (e: number) => void,
  authHeader: string,
  error: string,
  errorTooBig: string,
  open: string
};

type DownloadLinkProps = {
  value: string,
  open: string,
  timelineItemId: number,
  authHeader: string,
  error: string
};

type WebcamWidgetProps = {
  onPicture: (e: any) => void
};


class WebcamWidget extends React.Component<WebcamWidgetProps, {camActive: boolean, hover: boolean}> {
	
	constructor(props: WebcamWidgetProps) {
	    super(props);
	    this.state = {camActive: false, hover: false};
	}
	
	
	handleTakePhoto(data){
		this.setState({camActive: false, hover: this.state.hover});
			
		if (data){
			var blobBin = atob(data.split(',')[1]);
			var array: number[] = [];
			for(let i = 0; i < blobBin.length; i++) {
			    array.push(blobBin.charCodeAt(i));
			}
			let file = new File([new Uint8Array(array)], 'image.jpg');
			this.props.onPicture(file);
		}
	}
	
	
				
	render() {
		return <div>
			<StyledMargin>
		    <FontAwesomeIcon icon={faCamera as IconDefinition} size="4x" style={this.state.hover ? {cursor: "pointer",color: '#1e90ff'} : {cursor: "pointer",color: 'blue'}} 
				onMouseOver={() => this.setState({camActive: this.state.camActive, hover: true})} 
				onMouseOut={() => this.setState({camActive: this.state.camActive, hover: false})}  
				onClick={() => {
				this.setState({camActive: !this.state.camActive, hover: this.state.hover});
				}
			} /></StyledMargin>
			{this.state.camActive && <StyledDiv><Camera
			      onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
			      idealResolution = {{width: 1280, height: 960}}
			      imageCompression = {0.97}
			      isMaxResolution = {true}
			      isImageMirror = {false}
			      isSilentMode = {false}
			      isDisplayStartCameraError = {true}
			      isFullscreen = {true}
			      sizeFactor = {1}
				  onCameraStart = { (stream) => { (document.getElementById('outer-circle') as any).style.cursor = 'pointer'; } }
			    /><StyledClosePosition><FontAwesomeIcon icon={faTimesCircle as IconDefinition} size="4x" style={{cursor: "pointer", color: '#a11c1c'}} 
					onMouseOver={(e) => {(e.target as any).style.color = 'red'}} 
					onMouseOut={(e) => {(e.target as any).style.color = '#a11c1c'}}  
					onClick={() => {
					this.setState({camActive: !this.state.camActive, hover: this.state.hover});
					}
				} /></StyledClosePosition>

				</StyledDiv>}
		</div>
	}
}


class FileInput extends React.Component<FileInputProps, { value: string, isMounted: boolean, loading: boolean, error: string}> {
  constructor(props) {
    super(props);
    this.state = {value: '', isMounted: false, loading: false, error: ''};
  }

  uploadFile(file){
	const data = new FormData();
	data.append('file1', file);
	data.append('questionId', this.props.name);
	data.append('contentType', guessFileTypeFromExtension(file.name));
	data.append('participantTimelineId', String(this.props.timelineItemId));

	let url = api + '/mobile/uploadFile';
	
	this.setState({value: '', isMounted: this.state.isMounted, loading: true, error: ''});
	
	axios.post(url, data, {
		headers: {'content-type': 'multipart/form-data; boundary=--xxxbd41xxx--',
				  authorization: this.props.authHeader
      }})
      .then(res => {
		if (this.state.isMounted){
			if (res.data.status === 'SUCCESS'){
				this.props.onUploadDone(res.data.fileId);
				this.setState({value: 'FILE_ID:'+res.data.fileId, isMounted: this.state.isMounted, loading: false, error: ''});
			} else {
				if (res.data.code === 'EXCEPTION_FILE_SIZE_TOO_BIG'){
					this.setState({value: '', isMounted: this.state.isMounted, loading: false, error: this.props.errorTooBig});
				} else {
					this.setState({value: '', isMounted: this.state.isMounted, loading: false, error: this.props.error});
				}
			}
		}
      }).catch(_ =>{
		this.setState({value: '', isMounted: this.state.isMounted, loading: false, error: this.props.error});
	  })
  }

  render() {
    return (
		<div>
			<StyledInput
		        id={this.props.id}
		        type={this.props.type}
				accept={this.props.accept}
				disabled={this.state.loading}
		        onChange={(e) => {
				  if (e.target && e.target.files && e.target.files.length > 0){
						const file = e.target.files[0];
						this.uploadFile(file);
				  }
		        }}
			/>
			{this.props.accept === 'image/*' && <WebcamWidget
				onPicture={(e) => {
				this.uploadFile(e);
			}} />
			}
		{this.state.value && <DownloadLink value={this.state.value} timelineItemId={this.props.timelineItemId} authHeader={this.props.authHeader} open={this.props.open} error={this.props.error} />}
		<StyledError hidden={!this.state.error}>{this.state.error}</StyledError>
		<StyledSpinner data-loading={this.state.loading}>
			<FontAwesomeIcon icon={faCog as IconDefinition} size="2x" spin={true} />
		</StyledSpinner>
	</div>
    );
  }

  componentDidMount() {
	this.setState({value: this.state.value, isMounted: true, loading: this.state.loading, error: this.state.error})
  }

  componentWillUnmount(){
	this.setState({value: this.state.value, isMounted: false, loading: this.state.loading, error: this.state.error})
  } 
}

class DownloadLink extends React.Component<DownloadLinkProps, { loading: boolean, error: boolean}> {
	
	constructor(props) {
	    super(props);
	    this.state = {loading: false, error: false};
	  }
	
	render() {
	    return (
			<div>
		       <StyledP onClick={_ => {
					if (!this.state.loading){
						this.setState({loading: true, error: false});
						downloadFile(parseInt(this.props.value.substr(8)), this.props.timelineItemId, this.props.authHeader, () =>{
							this.setState({loading: false, error: false})
						}, () =>{
							this.setState({loading: false, error: true})
						});
					}
					}} >{this.props.open}</StyledP>
				<StyledError hidden={!this.state.error}>{this.props.error}</StyledError>
				<StyledSpinner data-loading={this.state.loading}>
					<FontAwesomeIcon icon={faCog as IconDefinition} size="2x" spin={true} />
				</StyledSpinner>
			</div>
    	);
	}
}





export function FilePicker({ name, type, accept, timelineItemId, disabled = false, value, onChange }: Props) {
  const [field, , { setValue }] = useField(name);

  const id = `${name}-${value === undefined ? '' : `-${value}`}`;
  const [{ active, token }, ] = useAuth();
  const authHeader = `Bearer ${token.find((t) => t.id === active)?.token}`;
  const { t } = useTranslation();
  const open = String(t(`form.open`));
  const error = String(t(`home.Error`));
  const errorTooBig = String(t(`form.fileTooBig`));

  return (
    <StyledWrapper>
	  {!disabled  
       ? <FileInput id={id} type={type} accept={accept} timelineItemId={timelineItemId} name={name} authHeader={authHeader} open={open} error={error} errorTooBig={errorTooBig} onUploadDone={(fileId) => {
		  setValue('FILE_ID:'+fileId);
        }}/>
	   : <DownloadLink value={String(field.value)} timelineItemId={timelineItemId} authHeader={authHeader} open={open} error={error} />
	  }
    </StyledWrapper>
  );
  
}

async function downloadFile(fileId: number, timeLineItemId: number, authHeader: string, onSucces, onFailure){
		
	const requestBody: DownloadFileRq = {
        fileId: fileId,
		timeLineItemId: timeLineItemId
    };

	let url = api + '/mobile/downloadFile';
	
	return axios.post(url, JSON.stringify(requestBody), {
		headers: {'content-type': 'application/json',
				  authorization: authHeader},
		responseType: 'blob'
	}).then((result) => {
		if (result.status === 200){
			const url = window.URL.createObjectURL(result.data);
			window.open(url);
			onSucces();
		} else {
			onFailure();
		}
		return result.data;
	
    }, error => {
        console.error(error);
		onFailure();
    });
	
}


function guessFileTypeFromExtension(fileName: string): string {
	let fileNameParts = fileName.split('.');
	if (fileNameParts.length === 0){
		return 'application/octet-stream';
	}
	let extension = fileNameParts[fileNameParts.length-1].toLowerCase();
	switch (extension){
	case 'ai': return 'application/postscript';
	case 'aif': return 'audio/x-aiff';
	case 'aifc': return 'audio/x-aiff';
	case 'aiff': return 'audio/x-aiff';
	case 'asc': return 'text/plain';
	case 'atom': return 'application/atom+xml';
	case 'au': return 'audio/basic';
	case 'avi': return 'video/x-msvideo';
	case 'bcpio': return 'application/x-bcpio';
	case 'bin': return 'application/octet-stream';
	case 'bmp': return 'image/bmp';
	case 'cdf': return 'application/x-netcdf';
	case 'cgm': return 'image/cgm';
	case 'class': return 'application/octet-stream';
	case 'cpio': return 'application/x-cpio';
	case 'cpt': return 'application/mac-compactpro';
	case 'csh': return 'application/x-csh';
	case 'css': return 'text/css';
	case 'dcr': return 'application/x-director';
	case 'dif': return 'video/x-dv';
	case 'dir': return 'application/x-director';
	case 'djv': return 'image/vnd.djvu';
	case 'djvu': return 'image/vnd.djvu';
	case 'dll': return 'application/octet-stream';
	case 'dmg': return 'application/octet-stream';
	case 'dms': return 'application/octet-stream';
	case 'doc': return 'application/msword';
	case 'dtd': return 'application/xml-dtd';
	case 'dv': return 'video/x-dv';
	case 'dvi': return 'application/x-dvi';
	case 'dxr': return 'application/x-director';
	case 'eps': return 'application/postscript';
	case 'etx': return 'text/x-setext';
	case 'exe': return 'application/octet-stream';
	case 'ez': return 'application/andrew-inset';
	case 'gif': return 'image/gif';
	case 'gram': return 'application/srgs';
	case 'grxml': return 'application/srgs+xml';
	case 'gtar': return 'application/x-gtar';
	case 'hdf': return 'application/x-hdf';
	case 'hqx': return 'application/mac-binhex40';
	case 'htm': return 'text/html';
	case 'html': return 'text/html';
	case 'ice': return 'x-conference/x-cooltalk';
	case 'ico': return 'image/x-icon';
	case 'ics': return 'text/calendar';
	case 'ief': return 'image/ief';
	case 'ifb': return 'text/calendar';
	case 'iges': return 'model/iges';
	case 'igs': return 'model/iges';
	case 'jnlp': return 'application/x-java-jnlp-file';
	case 'jp2': return 'image/jp2';
	case 'jpe': return 'image/jpeg';
	case 'jpeg': return 'image/jpeg';
	case 'jpg': return 'image/jpeg';
	case 'js': return 'application/x-javascript';
	case 'kar': return 'audio/midi';
	case 'latex': return 'application/x-latex';
	case 'lha': return 'application/octet-stream';
	case 'lzh': return 'application/octet-stream';
	case 'm3u': return 'audio/x-mpegurl';
	case 'm4a': return 'audio/mp4a-latm';
	case 'm4b': return 'audio/mp4a-latm';
	case 'm4p': return 'audio/mp4a-latm';
	case 'm4u': return 'video/vnd.mpegurl';
	case 'm4v': return 'video/x-m4v';
	case 'mac': return 'image/x-macpaint';
	case 'man': return 'application/x-troff-man';
	case 'mathml': return 'application/mathml+xml';
	case 'me': return 'application/x-troff-me';
	case 'mesh': return 'model/mesh';
	case 'mid': return 'audio/midi';
	case 'midi': return 'audio/midi';
	case 'mif': return 'application/vnd.mif';
	case 'mov': return 'video/quicktime';
	case 'movie': return 'video/x-sgi-movie';
	case 'mp2': return 'audio/mpeg';
	case 'mp3': return 'audio/mpeg';
	case 'mp4': return 'video/mp4';
	case 'mpe': return 'video/mpeg';
	case 'mpeg': return 'video/mpeg';
	case 'mpg': return 'video/mpeg';
	case 'mpga': return 'audio/mpeg';
	case 'ms': return 'application/x-troff-ms';
	case 'msh': return 'model/mesh';
	case 'mxu': return 'video/vnd.mpegurl';
	case 'nc': return 'application/x-netcdf';
	case 'oda': return 'application/oda';
	case 'ogg': return 'application/ogg';
	case 'pbm': return 'image/x-portable-bitmap';
	case 'pct': return 'image/pict';
	case 'pdb': return 'chemical/x-pdb';
	case 'pdf': return 'application/pdf';
	case 'pgm': return 'image/x-portable-graymap';
	case 'pgn': return 'application/x-chess-pgn';
	case 'pic': return 'image/pict';
	case 'pict': return 'image/pict';
	case 'png': return 'image/png';
	case 'pnm': return 'image/x-portable-anymap';
	case 'pnt': return 'image/x-macpaint';
	case 'pntg': return 'image/x-macpaint';
	case 'ppm': return 'image/x-portable-pixmap';
	case 'ppt': return 'application/vnd.ms-powerpoint';
	case 'ps': return 'application/postscript';
	case 'qt': return 'video/quicktime';
	case 'qti': return 'image/x-quicktime';
	case 'qtif': return 'image/x-quicktime';
	case 'ra': return 'audio/x-pn-realaudio';
	case 'ram': return 'audio/x-pn-realaudio';
	case 'ras': return 'image/x-cmu-raster';
	case 'rdf': return 'application/rdf+xml';
	case 'rgb': return 'image/x-rgb';
	case 'rm': return 'application/vnd.rn-realmedia';
	case 'roff': return 'application/x-troff';
	case 'rtf': return 'text/rtf';
	case 'rtx': return 'text/richtext';
	case 'sgm': return 'text/sgml';
	case 'sgml': return 'text/sgml';
	case 'sh': return 'application/x-sh';
	case 'shar': return 'application/x-shar';
	case 'silo': return 'model/mesh';
	case 'sit': return 'application/x-stuffit';
	case 'skd': return 'application/x-koan';
	case 'skm': return 'application/x-koan';
	case 'skp': return 'application/x-koan';
	case 'skt': return 'application/x-koan';
	case 'smi': return 'application/smil';
	case 'smil': return 'application/smil';
	case 'snd': return 'audio/basic';
	case 'so': return 'application/octet-stream';
	case 'spl': return 'application/x-futuresplash';
	case 'src': return 'application/x-wais-source';
	case 'sv4cpio': return 'application/x-sv4cpio';
	case 'sv4crc': return 'application/x-sv4crc';
	case 'svg': return 'image/svg+xml';
	case 'swf': return 'application/x-shockwave-flash';
	case 't': return 'application/x-troff';
	case 'tar': return 'application/x-tar';
	case 'tcl': return 'application/x-tcl';
	case 'tex': return 'application/x-tex';
	case 'texi': return 'application/x-texinfo';
	case 'texinfo': return 'application/x-texinfo';
	case 'tif': return 'image/tiff';
	case 'tiff': return 'image/tiff';
	case 'tr': return 'application/x-troff';
	case 'tsv': return 'text/tab-separated-values';
	case 'txt': return 'text/plain';
	case 'ustar': return 'application/x-ustar';
	case 'vcd': return 'application/x-cdlink';
	case 'vrml': return 'model/vrml';
	case 'vxml': return 'application/voicexml+xml';
	case 'wav': return 'audio/x-wav';
	case 'wbmp': return 'image/vnd.wap.wbmp';
	case 'wbmxl': return 'application/vnd.wap.wbxml';
	case 'wml': return 'text/vnd.wap.wml';
	case 'wmlc': return 'application/vnd.wap.wmlc';
	case 'wmls': return 'text/vnd.wap.wmlscript';
	case 'wmlsc': return 'application/vnd.wap.wmlscriptc';
	case 'wrl': return 'model/vrml';
	case 'xbm': return 'image/x-xbitmap';
	case 'xht': return 'application/xhtml+xml';
	case 'xhtml': return 'application/xhtml+xml';
	case 'xls': return 'application/vnd.ms-excel';
	case 'xml': return 'application/xml';
	case 'xpm': return 'image/x-xpixmap';
	case 'xsl': return 'application/xml';
	case 'xslt': return 'application/xslt+xml';
	case 'xul': return 'application/vnd.mozilla.xul+xml';
	case 'xwd': return 'image/x-xwindowdump';
	case 'xyz': return 'chemical/x-xyz';
	case 'zip': return 'application/zip';
	default: return 'application/octet-stream';
	}
}

const StyledP = styled.p`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledError = styled.p`
  color: red;
`;

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: .5rem;
  &[data-disabled="true"] {
    pointer-events: none;
  }
`;

const StyledMargin = styled.div`
  display: flex;
  margin-top: .5rem;
  justify-content: center;
`;

const StyledInput = styled.input`
  :checked ~ label {
    ::before {
      background-color: dodgerblue;
    }
    ::after {
      border-color: white;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 250;
  transition: opacity .25s ease;
  &[data-loading="false"] {
    opacity: 0;
    pointer-events: none;
  }
`;

const StyledClosePosition = styled.div`
  display: flex;
  position: fixed;
  right: .5rem;
  top: .5rem;
`;

const StyledSpinner = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 25;
  transition: opacity .25s ease;
  &[data-loading="false"] {
    opacity: 0;
    pointer-events: none;
  }
`;