import { FormikContextType, FormikProvider } from 'formik';
import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode,
  formik: FormikContextType<any>
};

export function Form({ children, formik }: Props) {
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {children}
      </form>
    </FormikProvider>
  );
}
