import styled from '@emotion/styled/macro';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useMatch, useNavigate } from 'react-router';

type Props = {
  text: string,
  path: string,
  icon: IconDefinition,
  activeIcon: IconDefinition
};

export function NavigationItem({ text, path, icon, activeIcon }: Props) {
  const navigate = useNavigate();
  const active = useMatch(path);

  return (
    <StyledItem onClick={() => navigate(path)} data-active={active}>
      <FontAwesomeIcon icon={active ? activeIcon : icon}/>
      <StyledText data-active={active}>
        {text}
      </StyledText>
    </StyledItem>
  );
}

const StyledItem = styled.button`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: .5rem 0;
  max-width: 5rem;
  color: white;
  opacity: .5;
  :hover {
    opacity: .75;
  }
  &[data-active="true"] {
    opacity: 1;
  }
`;

const StyledText = styled.span`
  padding-top: .25rem;
  font-size: .65rem;
  line-height: 1;
  transition: font-size .15s ease;
  &[data-active="true"] {
    font-size: .75rem;
  }
`;
