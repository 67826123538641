import React from 'react';

import { ValueProps } from '../types';

import { Input } from './Input';

export function NumberValue({ question, disabled, min, max, pattern }: ValueProps) {
  return (
    <Input name={String(question.id)} type="number" inputMode="decimal" disabled={disabled} minLength={min} maxLength={max} pattern={pattern}/>
  );
}
