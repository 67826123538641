import styled from '@emotion/styled/macro';
import React from 'react';

import { ValueProps } from '../types';

export function ReadonlyValue({ question }: ValueProps) {
  return (
    <StyledQuestion>
      {question.question}
    </StyledQuestion>
  );
}

const StyledQuestion = styled.div`
  position: relative;
  margin: 2.5rem 1rem 1rem;
  letter-spacing: 1px;
  opacity: .25;
  ::before {
    content: "";
    position: absolute;
    display: block;
    top: -1.25rem;
    left: calc(50% - 2rem);
    width: 4rem;
    height: .25rem;
    background-color: rgba(0, 0, 0, .5)
  }
`;
