import styled from '@emotion/styled/macro';
import { useFormik } from 'formik';
import { sha256 } from 'js-sha256';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { object, string } from 'yup';
import { Button } from '../components/Button';
import { Form } from '../components/Form';
import { Input } from '../components/Input';
import { Keypad } from '../components/Keypad';
import { Page } from '../components/Page';
import { app, Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { useAuth } from '../states/auth';
import { ParticipantProfileDetailRs } from '../messages-pwa';
import { Response } from '../types';
import { type } from '../utils/type';

type Values = {
	email: string,
	password: string
};

export function Login() {
	const { t } = useTranslation();
	const [attemptNumber, setAttemptNumber] = useState(Number(localStorage.getItem('attemptNumber')) || 0);
	const [maxNumberOfAttempts] = useState(4);
	const [cooldownTime] = useState(60);
	const [{ pin, active }, { logout, addToken, setUnlocked, setOnboarding }] = useAuth();
	const [invalid, setInvalid] = useState(0);
	const [show, setShow] = useState(false);
	const [showCooldown, setShowCooldown] = useState(false);
	const [, forgotError, , forgot] = useFetch('/mobile/forgotPassword', Method.Post);
	const [, error, loading, execute] = useFetch('/mobile/auth', Method.Post);
	const [, profileError, profileLoading, profileExecute] = useFetch<ParticipantProfileDetailRs>('/mobile/getParticipantProfileDetail', Method.Get, false);
	
	const formik = useFormik<Values>({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema: object<Values>({
			email: string().email().required().min(6),
			password: string().required().min(4)
		}),
		onSubmit: ({ email, password }) => {
			if (attemptNumber < maxNumberOfAttempts) {
				setOnboarding().then(() => execute({
					username: email,
					password,
					appCode: app
				}, '')).then((auth) => {
					if (!type<Response>(auth, 'status', 'SUCCESS')) {
						return;
					}
					const token = auth.headers.get('x-auth-token') || '';
					return Promise.all([token, profileExecute(undefined, token)]);
				}).then((response) => {
					if (!response) {
						setAttemptNumber(attemptNumber + 1);
						return;
					}
					if (!type<Response<ParticipantProfileDetailRs>>(response[1], 'status', 'SUCCESS')) {
						return;
					}
					const profile = response[1].participantProfileDetail;
					clearCooldownData();
					return addToken(profile.id as number, profile.name as string, response[0] || '');
				});
			} else {
				setShowCooldown(true);
				setShow(false);
			}
		}
	});

	useEffect(() => {
		let interval: any = null;

		if (attemptNumber >= maxNumberOfAttempts) {
			let cooldownStartTime = new Date(resetCooldownStartTime());
			interval = setInterval(() => {
				let currentCooldownTime = Math.floor((new Date().getTime() - cooldownStartTime.getTime()) / 1000);
				if (currentCooldownTime > cooldownTime) {
					clearCooldownData();
					clearInterval(interval);
					setShowCooldown(false);
				}
			}, 1000);
			
			setShowCooldown(true);
			setShow(false);
		}
		localStorage.setItem('attemptNumber', attemptNumber.toString());

		return () => clearInterval(interval);
	}, [attemptNumber, cooldownTime, maxNumberOfAttempts]);

	const clearCooldownData = () => {
		setAttemptNumber(0);
		localStorage.removeItem('attemptNumber');
		localStorage.removeItem('cooldownStartTime');
	}

	const resetCooldownStartTime = () => {
		let startTime = new Date().toString();
		localStorage.setItem('cooldownStartTime', startTime);
		return startTime;
	}

	useEffect(() => {
		if (error || profileError) {
			;
			setOnboarding(false)
				.then(logout);
		}
	}, [error, profileError, setOnboarding, logout]);

	if (pin) {
		return (
			<StyledPage title={t('authentication.login.title')} subtitle={t('authentication.login.mytext')} padding={true}>
				<Keypad
					onSubmit={(value) => {
						if (sha256.hmac(app, value) === pin) {
							return setUnlocked();
						} else if (invalid >= 5) {
							return logout();
						}
						setInvalid(invalid + 1);
					}}
				/>
				{Boolean(invalid) && (
					<StyledError>
						{t('authentication.login.errorText')}
					</StyledError>
				)}
				<StyledSpacer />
				<StyledLogout>
					<Button onClick={logout} text={t('main.profile-details.logout')} />
				</StyledLogout>
			</StyledPage>
		);
	}

	return active ? (
		<Navigate to="./verification" />
	) : (
			<Page title={t('registration.login-user.title')} subtitle={t('registration.login-user.login-info')} padding={true}>
				<Form formik={formik}>
					<Input name="email" type="email" label={t('registration.login-user.hintMail')} autoFocus />
					<Input name="password" type="password" label={t('registration.login-user.hintPassword')} />
					<StyledButton text={t('registration.login-user.button')} type="submit" disabled={loading || profileLoading} />
					<StyledForgot
						type="button"
						text={t('registration.login-user.forgotPassword')}
						onClick={() => {
							forgot({ email: formik.values.email });
							setShow(true);
						}}
					/>
				</Form>
				{(showCooldown) && (
					<StyledError>
						{t('registration.login-user.cooldownErr')}
					</StyledError>
				)}
				{(error || profileError || forgotError || show) && (
					<StyledError>
						{error || profileError || forgotError ? t('registration.login-user.wrong') : t('registration.login-user.newPassword')}
					</StyledError>
				)}
			</Page>
		);
}

const StyledButton = styled(Button)`
	margin: 1rem 0;
`;

const StyledError = styled.div`
	margin-top: 1rem;
	text-align: center;
	color: red;
`;

const StyledPage = styled(Page)`
	min-height: calc(100vh - 3rem);
`;

const StyledSpacer = styled.div`
	flex: 1;
`;

const StyledForgot = styled(Button)`
	color: black;
	background-color: transparent;
`;

const StyledLogout = styled.span`
	max-width: 8rem;
	margin-left: 13rem;
`;
