import styled from '@emotion/styled/macro';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router';
import { TimelineItemDto } from '../messages-pwa';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type Props = {
	item: Omit<TimelineItemDto, 'date'> & {
		date: Date
	},
	disabled: boolean
};

export function TimelineItem({ item, disabled }: Props) {
	const navigate = useNavigate();

	return (
		<StyledItem
			onClick={() => navigate(`./../dashboard/${item.id}`, { state: item })}
			disabled={disabled}
			timelineColor={item.timelineColor}
		>
			<div>
				<b>{item.title}</b>
				<StyledDate>
					{item.timelineCaption}
				</StyledDate>
				<StyledDate>
					{item.titleCaption}
				</StyledDate>
			</div>
			{!disabled && (
				<StyledIcon icon={faChevronRight as IconDefinition} />
			)}
		</StyledItem>
	);
}

const StyledItem = styled.button<{ timelineColor: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem 1rem 4rem;
  :disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, .25);
  }
  ::before, ::after {
    content: "";
    position: absolute;
    display: block;
  }
  ::before {
    top: 0;
    left: 1.8rem;
    width: .4rem;
    height: 100%;
    background-color: #f1f1f1;
  }
  ::after {
    top: calc(50% - .5rem);
    left: 1.5rem;
    width: 1rem;
    height: 1rem;
    background-color: #f1f1f1;
    border: 3px solid ${(props) => props.timelineColor};
    border-radius: 50%;
  }
  :not(:disabled) {
    :hover {
      background-color: #f1f1f1;
    }
    ::after {
      top: calc(50% - .75rem);
      left: 1.25rem;
      width: 1.5rem;
      height: 1.5rem;
      background-color: ${(props) => props.timelineColor};
    }
  }
  :first-of-type::before {
    top: 50%;
    height: 50%;
  }
  :last-child::before {
    height: 50%;
  }
  :first-of-type:last-child::before {
    height: 0;
  }
`;

const StyledDate = styled.div`
  margin-top: .5rem;
  font-size: .85rem;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`;
