import React from 'react';

import { ValueProps } from '../types';
import { FilePicker } from './FilePicker';

export function FileValue({ question, timelineItemId, disabled = false }: ValueProps) {

  let accept = '*';

  if (question.answerType.argument){
	if (question.answerType.argument.mimeFilter){
		accept = String(question.answerType.argument.mimeFilter);
	}
  }

  return (
	<FilePicker type="file" name={String(question.id)} timelineItemId={timelineItemId} disabled={disabled} accept={accept} />
  );
}