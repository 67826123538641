import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '../components/Page';
import { sanitize } from '../utils/sanitize';

export function Policy() {
  const { t } = useTranslation();

  return (
    <Page title={t('cookie-policy.title')} padding={true} backIcon={true}>
      <div dangerouslySetInnerHTML={{ __html: sanitize(t('cookie-policy.content')) }}/>
    </Page>
  );
}
