import { css, Global } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router';

import { Button } from '../components/Button';
import { Dialog } from '../components/Dialog';
import { GuardedRoute } from '../components/GuardedRoute';
import { Navigation } from '../components/Navigation';
import { useAuth } from '../states/auth';
import { useConsent } from '../states/consent';

import { Contact } from './Contact';
import { Dashboard } from './Dashboard';
import { DiaryDetail } from './DiaryDetail';
import { Disclaimer } from './Disclaimer';
import { Information } from './Information';
import { InformationDetails } from './InformationDetails';
import { Login } from './Login';
import { Pin } from './Pin';
import { Policy } from './Policy';
import { Profile } from './Profile';
import { ChangePassword } from './ChangePassword';
import { Verification } from './Verification';
import { baseurl } from '../constants';

export function Root() {
  const { t } = useTranslation();
  const [{ onboarding, pin, unlocked }] = useAuth();
  const [{ consent }, { setContent }] = useConsent();
  const navigate = useNavigate(); 
  const match = useMatch(baseurl + 'policy'); // TODO: this match should not be used , see dialog element outside of <Routes> which is wrong !!

  return (
    <>
      <Global
        styles={css`
          *, *::before, *:after {
            backface-visibility: hidden;
            box-sizing: border-box;
          }
          body {
            font-family: 'Open Sans', sans-serif;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
          }
          button {
            text-align: start;
            :hover:not(:disabled) {
              cursor: pointer;
            }
          }
          select, button, input {
            padding: 0;
            background: none;
            border: none;
            font: inherit;
            outline: none;
            appearance: none !important;
          }
          h1, h2, h3, h4, h5, h6 {
            margin: 0;
          }
          img, svg {
            vertical-align: middle;
          }
        `}
      />
      <StyledMain>
        <Routes basename={baseurl}>
          <GuardedRoute path="" element={<Login/>} enabled={!pin || !unlocked} redirect="dashboard"/>
          <GuardedRoute path="verification" element={<Verification/>} enabled={onboarding} redirect="dashboard"/>
          <GuardedRoute path="disclaimer" element={<Disclaimer/>} enabled={onboarding} redirect="dashboard"/>
          <GuardedRoute path="pin" element={<Pin/>} enabled={onboarding} redirect="dashboard"/>
          <GuardedRoute path="dashboard" element={<Dashboard/>} enabled={unlocked} redirect={baseurl}/>
          <GuardedRoute path="dashboard/:id" element={<DiaryDetail/>} enabled={unlocked} redirect={baseurl}/>
          <GuardedRoute path="profile" element={<Profile/>} enabled={unlocked} redirect={baseurl}/>
          <GuardedRoute path="change-password" element={<ChangePassword/>} enabled={unlocked} redirect={baseurl}/>
          <GuardedRoute path="contact" element={<Contact/>} enabled={unlocked} redirect={baseurl}/>
          <GuardedRoute path="information" element={<Information/>} enabled={unlocked} redirect={baseurl}/>
          <GuardedRoute path="information/:id" element={<InformationDetails/>} enabled={unlocked} redirect={baseurl}/>
          <Route path="policy" element={<Policy/>}/>
          <Route path="*" element={<Navigate to={baseurl}/>}/>
        </Routes>
      </StyledMain>
      <Dialog open={!consent && !match}>
        <StyledHeading>
          {t('cookie-policy.title')}
        </StyledHeading>
        {t('cookie-policy.excerpt')}
        <StyledButtons>
          <StyledButton text={t('cookie-policy.link')} onClick={() => navigate(baseurl + 'policy')}/>
          <Button text={t('cookie-policy.button')} onClick={setContent}/>
        </StyledButtons>
      </Dialog>
      {unlocked && (
        <Navigation/>
      )}
    </>
  );
}

const StyledMain = styled.main`
  display: flex;
  justify-content: center;
`;

const StyledHeading = styled.h3`
  margin-bottom: 1rem;
`;

const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
`;

const StyledButton = styled(Button)`
  color: black;
  background-color: transparent;
`;
