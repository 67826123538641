import styled from '@emotion/styled/macro';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';

import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { ControlStatus, TimelineItemDto } from '../messages-pwa';
import { sanitize } from '../utils/sanitize';

import { Button } from './Button';

type Props = {
	control: TimelineItemDto
};

export function MessageControl({ control: { id, text, status, timelineAutoNext } }: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [data, error, loading, execute] = useFetch('/mobile/sendAnswers', Method.Post);
	const visible = status !== ControlStatus.DONE && status !== ControlStatus.CLOSED;
    
	useEffect(() => {
		let isMounted = true;
		if (isMounted && timelineAutoNext) {
			navigate(`./../dashboard/${id}`, { state: id })
		}
		return () => { isMounted = false };
	}, [id, timelineAutoNext, navigate]);

	return data ? (
		<Navigate to="./../dashboard" />
	) : (
			<>
				<StyledMessage dangerouslySetInnerHTML={{ __html: sanitize(text) }} data-visible={visible} />
				{visible && (
					<Button
						text={t('questionaire.okButton')}
						type="submit"
						disabled={loading}
						onClick={() => execute({
							answerList: null,
							timelineItemId: id
						})}
					/>
				)}
				{error && (
					<StyledError>
						{t('questionaire.cannotSave')}
					</StyledError>
				)}
			</>
		);
}

const StyledMessage = styled.div`
  margin-top: .5rem;
  &[data-visible="true"] {
    margin-bottom: 1rem;
  }
`;

const StyledError = styled.div`
  margin-top: 1rem;
  text-align: center;
`;
