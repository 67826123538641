import styled from '@emotion/styled/macro';
import { faBars, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../components/Button';
import { Dialog } from '../components/Dialog';
import { Page } from '../components/Page';
import { PasswordLogin } from '../components/PasswordLogin';
import { Timeline } from '../components/Timeline';
import { useFetch } from '../hooks/useFetch';
import { useAuth } from '../states/auth';
import { ParticipationDto, ParticipationListRs } from '../messages-pwa';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Method } from '../constants';

export function Dashboard() {
	const { t } = useTranslation();
	const [{ active, token }, { setActive }] = useAuth();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [open, setOpen] = useState(false);
	const [tab, setTab] = useState('tasks');
	const [target, setTarget] = useState<ParticipationDto>();
	const [data, participationError, participationLoading] = useFetch<ParticipationListRs>('/mobile/getParticipationList', Method.Get, true, true, true);
	const updateTab = useCallback((value: string) => {
		setTab(value);
		setError(false);
		setEmpty(false);
	}, [setTab, setError, setEmpty]);
	const closeLogin = useCallback(() => {
		setOpen(false);
		setTarget(undefined);
	}, [setOpen, setTarget]);

	return (
		<Page
			title={`${t('main.slidemenu.home')} - ${token.find((v) => v.id === active)?.name}`}
			loading={loading || participationLoading}
			error={error || (!loading && empty && {
				message: t(tab === 'tasks' ? 'home.noTask' : 'home.noHistory'),
				icon: faBatteryEmpty as IconDefinition
			})}
			tabs={{
				tab,
				tabs: {
					tasks: t('home.myTaskItemTitle'),
					history: t('home.historyItemTitle')
				},
				setTab: updateTab
			}}
			actionIcon={!data?.participations.length ? undefined : {
				onClick: () => setOpen(!open),
				icon: faBars as IconDefinition
			}}
		>
			<Timeline
				url={tab === 'tasks' ? '/mobile/getTimelineByLimit2' : '/mobile/getTimelineHistoryByLimit2'}
				onlyLast={tab === 'tasks'}
				setLoading={setLoading}
				setError={setError}
				setEmpty={setEmpty}
			/>
			<Dialog open={open} setOpen={!target ? setOpen : undefined}>
				<StyledHeading>
					Accounts
				</StyledHeading>
				{target ? (
					<PasswordLogin participant={target} onClose={closeLogin} />
				) : participationError ? (
					<span>
						{t('home.Error')}
					</span>
				) : data?.participations.map((participation) => (
					<StyledButton
						key={participation.id}
						text={participation.name}
						onClick={() => {
							const obj = token.find((v) => v.id === participation.id);

							if (!obj) {
								return setTarget(participation);
							}

							setOpen(false);
							setActive(obj.id);
						}}
					/>
				))}
			</Dialog>
		</Page>
	);
}

const StyledHeading = styled.h3`
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  color: black;
  background-color: transparent;
`;
