import React from 'react';

import { ValueProps } from '../types';

import { Checkbox } from './Checkbox';

export function BooleanValue({ question, disabled = false }: ValueProps) {
  return (
    <Checkbox name={String(question.id)} label={question.question} disabled={disabled}/>
  );
}
