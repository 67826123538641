import styled from '@emotion/styled/macro';
import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { type } from '../utils/type';

type Props = {
  tab: string,
  tabs: Record<string, string>,
  setTab: (tab: string) => void
};

export function Tabs({ tab, tabs, setTab, ...props }: Props & ComponentPropsWithoutRef<'div'>) {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  useEffect(() => {
    if (state && type<{ tab: string }>(state, 'tab')) {
      setTab(state.tab);
    }
  }, [state, setTab]);

  return (
    <StyledTabs {...props}>
      {Object.keys(tabs).map((key) => (
        <StyledTab
          key={key}
          data-active={key === tab}
          onClick={() => {
            if (tab === key) {
              return;
            }

            navigate(pathname, {
              state: {
                ...state,
                tab: key
              },
              replace: true
            });
            setTab(key);
          }}
        >
          {tabs[key].toUpperCase()}
        </StyledTab>
      ))}
    </StyledTabs>
  );
}

const StyledTabs = styled.div`
  position: fixed;
  display: flex;
  top: 3rem;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  z-index: 50;
`;

const StyledTab = styled.button`
  flex: 1;
  text-align: center;
  height: 2.5rem;
  opacity: .25;
  line-height: 1;
  z-index: 1;
  &[data-active="true"] {
    font-weight: bold;
    opacity: 1;
  }
`;
