import styled from '@emotion/styled/macro';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useField } from 'formik';
import React, { ComponentPropsWithoutRef } from 'react';

import { type } from '../utils/type';
import { url } from '../utils/url';

type Props = {
	name: string,
	label?: string
} & (SelectProps | InputProps);

type SelectProps = {
	type: 'select'
} & ComponentPropsWithoutRef<'select'>;

type InputProps = {
	type: string
} & ComponentPropsWithoutRef<'input'>;

export function Input({ children, name, label, ...props }: Props) {
	const [field, { error, touched }, { setValue }] = useField(name);

	return (
		<StyledWrapper data-label={Boolean(label)}>
			{type<SelectProps>(props, 'type', 'select') ? (
				<StyledSelect
					id={name}
					data-error={Boolean(touched && error)}
					{...props}
					{...field}
					onChange={(e) => setValue(Number(e.target.value))}
				>
					{children}
				</StyledSelect>
			) : type<InputProps>(props, 'type') && (
				<StyledInput id={name} data-error={Boolean(touched && error)} {...props} {...field} />
			)}
			{label && (
				<StyledLabel htmlFor={name}>
					{label}
				</StyledLabel>
			)}
			{touched && error && (
				<StyledError>
					{error}
				</StyledError>
			)}
		</StyledWrapper>
	);
}

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: .5rem;
  &[data-label="true"] {
    padding-top: 1rem;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  min-height: 2.125rem;
  padding: .5rem .75rem;
  background-color: #f1f1f1;
  border-radius: .5rem;
  :focus ~ label, :not([value=""]) ~ label {
    top: 0;
    font-size: .75rem;
  }
  &[data-error="true"] {
    box-shadow: 0 0 0 2px tomato;
  }
`;

const StyledSelect = styled(StyledInput.withComponent('select'))`
  padding: .5rem 2rem .5rem .75rem;
  background-image: url('${url(faChevronDown as IconDefinition)}');
  background-size: 1rem;
  background-position: right .5rem top .625rem;
  background-repeat: no-repeat;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 1.5rem;
  left: .75rem;
  opacity: .5;
  white-space: nowrap;
  pointer-events: none;
  transition: top .25s ease, font-size .25s ease;
`;

const StyledError = styled.div`
  position: relative;
  margin-top: -1px;
  padding: .5rem .75rem;
  color: white;
  background-color: tomato;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  box-shadow: 0 -2px 0 2px tomato;
  z-index: -1;
`;


