import React from 'react';
import { Navigate, Route, RouteProps } from 'react-router';

type Props = RouteProps & {
  enabled: boolean,
  redirect: string
};

export function GuardedRoute({ path, element, enabled, redirect }: Props) {
  return (
    <Route
      path={path}
      element={enabled ? element : <Navigate to={redirect}/>}
    />
  );
}
