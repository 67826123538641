import { useEffect, useState } from 'react';

export function useActivity() {
  const [activity, setActivity] = useState(Date.now());

  useEffect(() => {
    const updateActivity = () => setActivity(Date.now());

    document.addEventListener('click', updateActivity);
    document.addEventListener('scroll', updateActivity);

    return () => {
      document.removeEventListener('click', updateActivity);
      document.removeEventListener('scroll', updateActivity);
    };
  }, []);

  return activity;
}
