import styled from '@emotion/styled/macro';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router';

import { DiaryControl } from '../components/DiaryControl';
import { MessageControl } from '../components/MessageControl';
import { Page } from '../components/Page';
import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { ControlType, ControlStatus, TimelineItemDto } from '../messages-pwa';
import { type } from '../utils/type';

export function DiaryDetail() {
	const { t } = useTranslation();
	const { state } = useLocation();
	const [, , , execute] = useFetch('/mobile/setSeen', Method.Post);

	useEffect(() => {
		if (!type<TimelineItemDto & { date: Date }>(state, 'id')
			|| state.status !== ControlStatus.NEW) {
			return;
		}

		execute({
			timelineItemId: state.id
		});
	}, [state, execute]);

	if (!type<TimelineItemDto & { date: Date }>(state, 'id')) {
		return (
			<Navigate to="dashboard" />
		);
	}

	return (
		<Page title={t(`main.controltype.${state.controlType.toLowerCase()}`)} padding={true} backIcon={true}>
			<StyledHeading>
				{state.title}
			</StyledHeading>
			<StyledDate>
				{state.titleCaption}
			</StyledDate>
			{type<ControlType>(state, 'controlType',
				ControlType.DIARY,
				ControlType.SCORELIST,
				ControlType.QUESTIONNAIRE,
				ControlType.TASK) ? (
					<DiaryControl control={state} />
				) : type<ControlType>(state, 'controlType', ControlType.START, ControlType.MESSAGE, ControlType.END) && (
					<MessageControl control={state} />
				)}
		</Page>
	);
}

const StyledHeading = styled.h2`
  margin-bottom: 1rem;
`;

const StyledDate = styled.div`
  align-self: center;
  padding: .25rem .5rem;
  color: rgba(0, 0, 0, .5);
  background-color: #f1f1f1;
  border-radius: .4rem;
  font-size: .75rem;
  font-weight: bold;
`;
