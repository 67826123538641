import { createDakpan } from "dakpan";

type State = {
	nextitem: boolean;
};

export const [NextItemProvider, useNextItem] = createDakpan<State>({
	nextitem: Boolean(sessionStorage.getItem("nextItem") || false)
})({
	setNextItem: (flag: boolean) => () => {
		sessionStorage.setItem("nextItem", ""+flag);
		return {
			nextitem: flag
		};
	}
});