import React, { ReactNode, useEffect } from 'react';

import { useActivity } from '../hooks/useActivity';
import { useAuth } from '../states/auth';

type Props = {
  children?: ReactNode,
  ms: number
};

export function IdleTimeout({ children, ms }: Props) {
  const [{ unlocked }, { setUnlocked }] = useAuth();
  const activity = useActivity();

  useEffect(() => {
    if (!unlocked) {
      return;
    }

    const timeout = setTimeout(() => setUnlocked(false), ms);

    return () => clearTimeout(timeout);
  }, [ms, unlocked, activity, setUnlocked]);

  return (
    <>
      {children}
    </>
  );
}
