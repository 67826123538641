import styled from '@emotion/styled/macro';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { date, number, object, string, boolean } from 'yup';

import { Button } from '../components/Button';
import { Form } from '../components/Form';
import { Input } from '../components/Input';
import { Page } from '../components/Page';
import { ProfileEntry } from '../components/ProfileEntry';
import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { useAuth } from '../states/auth';
import { useNavigate } from 'react-router';
import { GetCustomFormRs, ParticipantProfileDetailRs } from '../messages-pwa';
import { path } from '../utils/path';
import { unpack } from '../utils/unpack';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export function Profile() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [, { logout }] = useAuth();
	const [editable, setEditable] = useState(false);
	const [data, error, loading, execute] = useFetch<GetCustomFormRs>('/mobile/getCustomForm', Method.Post);
	const [profile, profileError, profileLoading, profileExecute] = useFetch<ParticipantProfileDetailRs>('/mobile/getParticipantProfileDetail');
	const [, saveError, , save] = useFetch<GetCustomFormRs>('/mobile/saveParticipantProfileDetail', Method.Post);

	const formTypes: Record<string, [string, any]> = {
		TEXT: ['text', string()],
		TEXT_EMAIL: ['email', string().email()],
		SELECT: ['select', string()],
		NUMBER: ['number', number()],
		NUMBER_PHONE: ['phone', string()],
		BOOLEAN: ['boolean', boolean()],
		DATE: ['date', date()]
	};

	const formik = useFormik({
		initialValues: unpack((profile && data?.form.fields.reduce((previous, current) => ({
			...previous,
			[current.property]: path(current.property, profile.participantProfileDetail) || ''
		}), {})) || {}),
		enableReinitialize: true,
		validationSchema: object(data?.form.fields.reduce((previous, current) => {
			if (current.property.indexOf('.') !== -1) {
				return previous;
			}

			let schema = formTypes[current.type][1];

			if (current.required) {
				schema = schema.required(t('form.error.required'));
			}

			if (current.pattern) {
				schema = schema.matches(new RegExp(current.pattern), t('main.profile-edit.invalid'));
			}

			return {
				...previous,
				[current.property]: schema
			};
		}, {}) || {}),
		onSubmit: (values) => {
			save(values).then(() => {
				profileExecute();
				setEditable(false);
			});
		}
	});

	useEffect(() => {
		execute({
			key: 'PARTICIPANT_PROFILE_FORM'
		});
	}, [execute]);

	return (
		<StyledPage
			loading={loading || profileLoading}
			error={Boolean(error || profileError)}
			padding={true}
			title={t('main.profile-details.title')}
			actionIcon={{
				onClick: () => setEditable(!editable),
				icon: faPen as IconDefinition
			}}
		>
			{data?.form && profile?.participantProfileDetail && (
				<>
					<Form formik={formik}>
						{editable ? (
							data.form.fields.filter(f => f.editable === true && path(f.property, profile.participantProfileDetail)).map((field) => (
							<Input
								key={field.property}
								name={field.property}
								label={field.label}
								type={formTypes[field.type][0]}
								disabled={!field.editable}
							>
								{field.options?.map((option) => (
									<option key={option.value} value={option.value}>{option.label}</option>
								))}
							</Input>
							))
						) : (
							data.form.fields.filter(f => f.visible === true && path(f.property, profile.participantProfileDetail)).map((field) => (
								<ProfileEntry
									key={field.property}
									title={field.label}
									content={String(path(field.property, profile.participantProfileDetail) || '-')}
								/>
							))
						)}
						{editable ? (
							<Button type="submit" text={t('main.profile-edit.button')} />
						) : (
							<Button type="button" text={t('main.profile-details.logout')} onClick={logout} />
						)}
					</Form>
					<StyledLink onClick={() => navigate('../change-password')}>{t('main.profile-edit.changePassword')}</StyledLink>
				</>
			)}
			{saveError && editable && (
				<StyledError>
					{t('registration.login-user.unknownhost')}
				</StyledError>
			)}
			{error && (
				<StyledMessage>
					{t('home.Error')}
					<Button type="button" text={t('main.profile-details.logout')} onClick={logout} />
				</StyledMessage>
			)}
		</StyledPage>
	);
}

const StyledPage = styled(Page)`
  justify-content: space-between;
`;

const StyledError = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const StyledMessage = styled.span`
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, .25);
  display: inline-grid;
  margin-top: 5rem;
  line-height: 1;
`;

const StyledLink = styled.span`
	display: flex;
	justify-content: center;
	margin-top: 20px;
	cursor: pointer;
`
