import styled from '@emotion/styled/macro';
import { faEnvelope, faExternalLinkAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { Button } from '../components/Button';
import { Page } from '../components/Page';
import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { ContactRs } from '../messages-pwa';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export function Contact() {
	const [data, error, loading] = useFetch<ContactRs>('/mobile/getContact', Method.Get);

	return (
		<Page title="Contact" loading={loading} error={(error) && true}>
			{data && data.contactDetail && (
				<>
					{data.contactDetail.addressDto.gpsLatitude !== 0 && data.contactDetail.addressDto.gpsLongitude !== 0 && (
						<StyledMap
							src={`https://maps.google.com/maps?q=
            ${data.contactDetail.addressDto.address1},${data.contactDetail.addressDto.address2},${data.contactDetail.addressDto.address3}&output=embed`}
							frameBorder={0}
						/>)}
					<StyledName>
						<h3>
							{data.contactDetail.studySiteName}
						</h3>
						<StyledAddress>
							{data.contactDetail.addressDto.address1}
						</StyledAddress>
						<StyledAddress>
							{data.contactDetail.addressDto.address2} {data.contactDetail.addressDto.address3}
						</StyledAddress>
						<StyledAddress>
							{data.contactDetail.addressDto.postalCode} {data.contactDetail.addressDto.city}
						</StyledAddress>
					</StyledName>
					<StyledExtra>
						<Button
							icon={faEnvelope as IconDefinition}
							onClick={() => window.open(`mailto:${data.contactDetail.email}`)}
						/>
						<Button
							icon={faPhone as IconDefinition}
							onClick={() => window.open(`tel:${data.contactDetail.phone}`)}
						/>
						<Button
							icon={faExternalLinkAlt as IconDefinition}
							onClick={() => window.open(`https://${data.contactDetail.website}`)}
						/>
					</StyledExtra>
				</>
			)}
		</Page>
	);
}

const StyledMap = styled.iframe`
  width: 100%;
  height: 12rem;
`;

const StyledName = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: -2rem 1rem 0;
  padding: .5rem .75rem;
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 0 .5rem rgba(0, 0, 0, .1);
`;

const StyledExtra = styled.div`
  display: flex;
  margin: 1rem;
  > * + * {
    margin-left: 1rem;
  }
`;

const StyledAddress = styled.span`
  font-size: .8rem;
  :first-of-type {
    margin-top: .5rem;
  }
`;
