import styled from '@emotion/styled/macro';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Page } from '../components/Page';
import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { InformationListRs } from '../messages-pwa';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export function Information() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ data, error, loading ] = useFetch<InformationListRs>('/mobile/getInformationList', Method.Get);
  const [ type, setType ] = useState('info');

  return (
    <Page
      title={t(`help.${type}`)}
      loading={loading}
      error={error && true}
      tabs={{
        tab: type,
        tabs: {
          info: t('help.info'),
          faq: t('help.faq')
        },
        setTab: setType
      }}
    >
      {data &&
      data.items && (
        <div>
          {data &&
            data.items.filter((entry) => entry.type === type.toUpperCase()).map((entry) => (
              <StyledItem key={entry.id} onClick={() => navigate(`${entry.id}`, { state: entry })}>
                <span>
                  {entry.title}
                  {entry.description && <StyledExtra>- {entry.description}</StyledExtra>}
                </span>
                <StyledIcon icon={faChevronRight as IconDefinition} />
              </StyledItem>
            ))}
        </div>
      )}
    </Page>
  );
}

const StyledItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  :hover {
    background-color: #f1f1f1;
  }
`;

const StyledExtra = styled.div`
  margin-top: .5rem;
  font-size: .85rem;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`;
