import React from 'react';

import { ValueProps } from '../types';

import { Checkbox } from './Checkbox';

export function MultiSelectValue({ question, disabled = false }: ValueProps) {
  return (
    <>
      {question.possibleAnswerValues && question.possibleAnswerValues.map((answer) => (
        <Checkbox
          key={answer.id}
          name={String(question.id)}
          label={answer.label && answer.label !== '' ? answer.label : answer.value || ''}
          value={answer.id}
          disabled={disabled}
        />
      ))}
    </>
  );
}
