import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export function url(icon: IconDefinition) {
  const path = icon.icon[4];

  return `data:image/svg+xml;charset=utf-8,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="${path}"/>
    </svg>
  `;
}
