import { local } from './local';

export function worker() {
  if (process.env.NODE_ENV !== 'production' || !('serviceWorker' in navigator)) {
    return;
  }

  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

  if (publicUrl.origin !== window.location.origin) {
    return;
  }

  window.addEventListener('load', () => {
    const url = `${process.env.PUBLIC_URL}/service-worker.js`;

    if (!local()) {
      return register(url);
    }

    fetch(url, { headers: { 'Service-Worker': 'script' } }).then((response) => {
      const contentType = response.headers.get('content-type');

      if (response.status !== 404 && (!contentType || contentType.indexOf('javascript') !== -1)) {
        return register(url);
      }

      navigator.serviceWorker.ready
        .then((registration) => registration.unregister())
        .then(() => window.location.reload());
    }).catch(() => console.log('No internet connection found. App is running in offline mode.'));
  });
}

function register(url: string) {
  navigator.serviceWorker.register(url).catch((error) => {
    console.error('Error during service worker registration:', error);
  });
}
