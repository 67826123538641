import styled from '@emotion/styled/macro';
import { faAddressCard, faHome, faInfoCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import {
  faAddressCard as faAddressCardSolid,
  faHome as faHomeAltSolid,
  faInfoCircle as faInfoCircleSolid,
  faUser as faUserSolid
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { baseurl } from '../constants';
import { NavigationItem } from './NavigationItem';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export function Navigation() {
	const { t } = useTranslation();
	return (
		<StyledNavigation>
			<NavigationItem
				text={t('main.slidemenu.home')}
				path={baseurl + 'dashboard'}
				icon={faHome as IconDefinition}
				activeIcon={faHomeAltSolid as IconDefinition}
			/>
			<NavigationItem
				text={t('main.slidemenu.profile')}
				path={baseurl + '/profile'}
				icon={faUser as IconDefinition}
				activeIcon={faUserSolid as IconDefinition}
			/>
			<NavigationItem
				text={t('main.slidemenu.contact')}
				path={baseurl + '/contact'}
				icon={faAddressCard as IconDefinition}
				activeIcon={faAddressCardSolid as IconDefinition}
			/>
			<NavigationItem
				text={t('main.slidemenu.info')}
				path={baseurl + '/information'}
				icon={faInfoCircle as IconDefinition}
				activeIcon={faInfoCircleSolid as IconDefinition}
			/>
		</StyledNavigation>
	);
}

const StyledNavigation = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-around;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 3rem;
  background-color: dodgerblue;
  z-index: 50;
`;
