import styled from '@emotion/styled/macro';
import { faBackspace, faCheck } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';

import { range } from '../utils/range';

import { Button } from './Button';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type Props = {
	onSubmit: (value: string) => void
};

export function Keypad({ onSubmit }: Props) {
	const [pin, setPin] = useState('');
	const [show, setShow] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setShow(false), 250);

		return () => clearTimeout(timeout);
	}, [pin, setShow]);

	const appendNumber = (num: number) => {
		if (pin.length < 4) {
			setPin(pin + num);
			setShow(true);
		}
	};

	return (
		<StyledWrapper>
			<StyledPin>
				{range(0, 4).map((i) => (
					<StyledPinNumber key={i}>
						{show && i === pin.length - 1 ? pin[i] : i < pin.length ? '•' : ''}
					</StyledPinNumber>
				))}
			</StyledPin>
			<StyledKeypad
				onKeyDown={(event: React.KeyboardEvent) => {
						if (event.keyCode === 13) {
							onSubmit(pin);
							setPin('');
						} else if (event.keyCode === 8) {
							setPin(pin.slice(0, -1));
							setShow(false);
						} else if (event.keyCode >= 48 && event.keyCode <= 57) {
							appendNumber(event.keyCode - 48);
						}
					}}
				>
				{range(1, 10).map((i) => (
					<StyledKey autoFocus={i===1} key={i} text={String(i)} onClick={() => appendNumber(i)}/>
				))}
				<StyledKey
					icon={faBackspace as IconDefinition}
					onClick={() => {
						setPin(pin.slice(0, -1));
						setShow(false);
					}}
				/>
				<StyledKey text="0" onClick={() => appendNumber(0)} />
				<StyledKey
					icon={faCheck as IconDefinition}
					disabled={pin.length < 4}
					onClick={() => {
						onSubmit(pin);
						setPin('');
					}}
				/>
			</StyledKeypad>
		</StyledWrapper>
	);
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPin = styled.div`
  display: flex;
  justify-content: space-between;
  width: 10rem;
  margin-bottom: 1rem;
`;

const StyledPinNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 3rem;
  background-color: #f1f1f1;
  border-radius: .5rem;
  font-size: 1.75rem;
`;

const StyledKeypad = styled.div`
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: 1fr 1fr 1fr;
  width: 10rem;
`;

const StyledKey = styled(Button)`
  height: 3rem;
`;
