import styled from '@emotion/styled/macro';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { app, Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { useAuth } from '../states/auth';
import { ParticipationDto, ParticipantProfileDetailRs } from '../messages-pwa';
import { type } from '../utils/type';

import { Button } from './Button';
import { Form } from './Form';
import { Input } from './Input';

type Props = {
  participant: ParticipationDto,
  onClose: () => void
};

type Values = {
  email: string,
  password: string
};

export function PasswordLogin({ participant, onClose }: Props) {
  const { t } = useTranslation();
  const [, { addToken }] = useAuth();
  const [data, error, loading] = useFetch<ParticipantProfileDetailRs>('/mobile/getParticipantProfileDetail');
  const [, loginError, loginLoading, execute] = useFetch('/mobile/auth', Method.Post, false, false);
  const formik = useFormik<Values>({
    initialValues: {
      email: data?.participantProfileDetail.email as string || '',
      password: ''
    },
    enableReinitialize: true,
    onSubmit: ({ email, password }) => {
      execute({
        username: email,
        password,
        appCode: `${app}:${participant.id}`
      }, '').then((auth) => {
        if (!type<Response>(auth, 'status', 'SUCCESS')) {
          return;
        }
        const token = auth.headers.get('x-auth-token') || '';
        onClose();

        return addToken(participant.id, participant.name, token);
      });
    }
  });

  useEffect(() => {
    if (error) {
      onClose();
    }
  }, [error, onClose]);

  return (
    <div>
      <StyledName>
        {participant.name}
      </StyledName>
      {t('switch')}
      <Form formik={formik}>
        <Input name="password" type="password" label={t('registration.login-user.password')}/>
        <Button type="submit" text={t('registration.login-user.button')} disabled={loading || loginLoading}/>
      </Form>
      {loginError && (
        <StyledError>
          {t('registration.login-user.wrong')}
        </StyledError>
      )}
    </div>
  );
}

const StyledName = styled.div`
  margin: -.5rem 0 .5rem;
`;

const StyledError = styled.div`
  margin-top: 1rem;
  text-align: center;
`;
