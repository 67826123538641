export function type<T>(obj: any | undefined, key: string, ...values: any[]): obj is T {
  if (!obj) {
    return false;
  }

  const actual = obj[key];

  if (!values.length) {
    return Boolean(actual);
  }

  return values.some((value) => actual === value);
}
