import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'normalize.css';
import React from 'react';
import { render } from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { IdleTimeout } from './components/IdleTimeout';
import { locales } from './constants';
import { Root } from './pages/Root';
import { AuthProvider } from './states/auth';
import { ConsentProvider } from './states/consent';
import { worker } from './utils/worker';
import { NextItemProvider } from './states/nextitem';
import { BackItemProvider } from './states/backclick';

const localeKeys = Object.keys(locales);
i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		debug: process.env.NODE_ENV !== 'production',
		fallbackLng: localeKeys[0],
		whitelist: localeKeys,
		detection: {
			order: ['querystring',/*'localStorage',*/ 'navigator'],
			caches: ['localStorage'],
			lookupLocalStorage: 'PWA_LANGUAGE',
			checkWhitelist: true /* TODO: remove undocumented option of LanguageDetector.... */
		},
		interpolation: {
			escapeValue: false
		},
		resources: localeKeys.reduce((previous, current) => ({
			...previous,
			[current]: {
				translation: locales[current][0]
			}
		}), {})
	});

render((
	<BrowserRouter>
		<AuthProvider>
			<ConsentProvider>
				<NextItemProvider>
					<BackItemProvider>
						<IdleTimeout ms={1000 * 60 * 15}>
							<Root />
						</IdleTimeout>
					</BackItemProvider>
				</NextItemProvider>
			</ConsentProvider>
		</AuthProvider>
	</BrowserRouter>
), document.getElementById('root'));

worker();
