import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router';

import { Page } from '../components/Page';
import { Method } from '../constants';
import { useFetch } from '../hooks/useFetch';
import { GetInfoDetailsRs, InformationItemDto } from '../messages-pwa';
import { sanitize } from '../utils/sanitize';
import { type } from '../utils/type';

type Params = {
  id: string
};

export function InformationDetails() {
  const { t } = useTranslation();
  const { id } = useParams<Params>();
  const { state } = useLocation();
  const [data, , loading, execute] = useFetch<GetInfoDetailsRs>('/mobile/getInfoDetails', Method.Post);

  useEffect(() => {
    execute({ id });
  }, [id, execute]);

  if (!type<InformationItemDto>(state, 'id')) {
    return (
      <Navigate to="information"/>
    );
  }

  return (
    <Page title={t(`help.${state.type.toLowerCase()}`)} padding={true} loading={loading} backIcon={true}>
      <h2>{state.title}</h2>
      {data && (
        <span dangerouslySetInnerHTML={{ __html: sanitize(data.details) }}/>
      )}
    </Page>
  );
}
