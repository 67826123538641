import { css, Global } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode,
  open: boolean,
  setOpen?: (open: boolean) => void
};

export function Dialog({ children, open, setOpen }: Props) {
  return (
    <StyledBackdrop onClick={() => setOpen?.(false)} data-open={open}>
      {open && (
        <Global
          styles={css`
            body {
              overflow-y: hidden;
            }
          `}
        />
      )}
      <StyledDialog onClick={(e) => e.stopPropagation()}>
        {children}
      </StyledDialog>
    </StyledBackdrop>
  );
}

const StyledBackdrop = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  z-index: 75;
  transition: opacity .25s ease;
  &[data-open="false"] {
    opacity: 0;
    pointer-events: none;
  }
`;

const StyledDialog = styled.div`
  max-width: calc(100% - 2rem);
  margin: 1rem;
  padding: 1rem;
  max-width: 33rem;
  background-color: white;
  border-radius: .5rem;
  overflow: hidden;
`;
