import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Keypad } from '../components/Keypad';
import { Page } from '../components/Page';
import { useAuth } from '../states/auth';

export function Pin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, { login }] = useAuth();
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);

  return (
    <Page
      title={t(code ? 'registration.setup-pin.verify' : 'registration.setup-pin.setup')}
      subtitle={t(code ? 'registration.setup-pin.verify-info' : 'registration.setup-pin.setup-info')}
      padding={true}
    >
      <Keypad
        onSubmit={(value) => {
          if (!code) {
            return setCode(value);
          } else if (value !== code) {
            return setError(true);
          }

          login(value).then(() => navigate('./../dashboard'));
        }}
      />
      {error && (
        <StyledError>
          {t('registration.setup-pin.verify-err')}
        </StyledError>
      )}
    </Page>
  );
}

const StyledError = styled.div`
  margin-top: 1rem;
  text-align: center;
`;
